import productService from '../../services/productService';
import productPricingService from '../../services/productPricingService';
import orderSettingsService from '../../services/orderSettingsService.js';
import siteSettingsService from '../../services/siteSettingsService.js';

import { cloudFunctionsPost, cloudFunctionsGet, cloudFunctionsPatch } from '../../modules/cloud-function-wrapper.js';
import { dbFS, storageRef } from '@/services/firebaseInit';

const state = {
  products: [],
  isProductsLoading: false,
  currentProduct: {},
  selectedProducts: [],
  productCounts: {},
  integrations: []
};

const getters = {
  getProducts(state) {
    return state.products;
  },
  getProductsLoadingState(state) {
    return state.isProductsLoading;
  },
  getCurrentProduct(state) {
    return state.currentProduct;
  },
  getSelectedProducts(state) {
    return state.selectedProducts;
  },
  getProductCounts(state) {
    return state.productCounts;
  },
  getIntegrations(state) {
    return state.integrations;
  }
};

const mutations = {
  setProducts(state, payload) {
    state.products = payload;
  },
  setProductsLoadingState(state, payload) {
    state.isProductsLoading = payload;
  },
  setCurrentProduct(state, payload) {
    state.currentProduct = payload;
  },
  setSelectedProducts(state, payload) {
    state.selectedProducts = payload;
  },
  setProductCounts(state, payload) {
    state.productCounts = payload;
  },
  removeProduct(state, productID) {
    state.products = state.products.filter(product => product.product_firebase_id !== productID);
  },
  setIntegrations(state, integrations) {
    state.integrations = integrations;
  }
};

const actions = {
  getDefaultProducts({ commit }) {
    commit('setProductsLoadingState', true);
    return new Promise(resolve => {
      dbFS.collection('products').onSnapshot(productSnapshot => {
        commit('setProducts', []);
        const productData = productSnapshot.docs.map(product => {
          return { product_firebase_id: product.id, ...product.data() };
        });
        commit('setProducts', productData);
        commit('setProductsLoadingState', false);
        resolve(productData);
      });
    });
  },
  getProductsBySiteID({ commit }, siteID) {
    console.log('get products per site: ', siteID);
    commit('setProductsLoadingState', true);
    return new Promise((resolve, reject) => {
      dbFS
        .collection(`sites/${siteID}/products`)
        .get()
        .then(querySnapshot => {
          const products = [];
          querySnapshot.forEach(doc => {
            products.push({ product_firebase_id: doc.id, ...doc.data() });
          });
          commit('setProducts', products);
          commit('setProductsLoadingState', false);
          console.log('[getProductsBySiteID] products: ', products);
          resolve(products);
        })
        .catch(error => {
          console.error('Error getting products by site id:', error);
          commit('setProductsLoadingState', false);
          reject(error);
        });
    });
  },
  getDefaultProductByID(_context, payload) {
    return productService.getById(payload);
  },
  async getCustomProductByID(_context, payload) {
    const product = await siteSettingsService.getById(`${payload.siteID}/products/${payload.productID}`);
    return product;
  },
  getProductAndWatch({ commit }, payload) {
    //this is needed for pricing , since the product can be updated by another user on a different page

    const collectionPath = payload?.siteId === 'default' ? 'products' : 'sites';
    const documentPath = payload?.siteId === 'default' ? payload.productId : `${payload.siteId}/products/${payload.productId}`;

    dbFS
      .collection(collectionPath)
      .doc(documentPath)
      .onSnapshot(
        doc => {
          commit('setCurrentProduct', doc.exists ? doc.data() : {});
        },
        error => {
          console.error('Error fetching and watching product:', error);
        }
      );
  },
  async getOrderProcesses() {
    //we need to keep the checkboxes in sync with the available order processes
    const orderProcessesDefault = await orderSettingsService.getById('default', { isIdReturned: true });
    return Object.keys(orderProcessesDefault.orderProcesses).map(key => ({ key: `is_${key}`, label: key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()) }));
  },
  async validateProductData(_context, payload) {
    try {
      const promises = [];

      if (payload.data.salesforce_product_id) {
        promises.push(
          payload.siteID === 'default'
            ? productService.getByProperty('salesforce_product_id', payload.data.salesforce_product_id, { isIdReturned: true, isArray: true })
            : siteSettingsService.getProductsBySiteID(payload.siteID, 'salesforce_product_id', payload.data.salesforce_product_id)
        );
      }
      if (payload.data.salesforce_pricebook_entry_id) {
        promises.push(
          payload.siteID === 'default'
            ? productService.getByProperty('salesforce_pricebook_entry_id', payload.data.salesforce_pricebook_entry_id, { isIdReturned: true, isArray: true })
            : siteSettingsService.getProductsBySiteID(payload.siteID, 'salesforce_pricebook_entry_id', payload.data.salesforce_pricebook_entry_id)
        );
      }

      const results = await Promise.all(promises);

      results.forEach(product => {
        const docId = product.find(doc => doc._id !== payload.id && doc.id !== payload.id);
        if (docId) {
          throw new Error(`Product data already exists in product ${docId?._id || docId.id}`);
        }
      });
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async uploadProductImage(_context, payload) {
    //unique name for easier identification and search , also to avoid overwriting
    const fileName = `${payload.name.replaceAll(' ', '_')}_${Date.now()}`;
    const fileRef = storageRef.child(`products/${fileName}`);
    try {
      const snapshot = await fileRef.put(payload.file);
      return snapshot.ref.getDownloadURL();
    } catch (error) {
      console.error('[uploadProductImage error]: ', error);
      throw new Error(`Could not save the image to Firebase storage. ${error.response?.data?.message || error.message || ''}`);
    }
  },
  async addProduct({ dispatch }, payload) {
    //create the product in firestore
    console.log('add new product payload: ', payload);
    let product_firebase_id = '';
    let product = {};
    if (payload.siteID === 'default') {
      payload.modified_at = await dispatch('getCurrentDate');
      product_firebase_id = await productService.add(payload);
      product = { product_firebase_id, ...payload.data };
    } else {
      product_firebase_id = await siteSettingsService.addProductToSite(payload.siteID, payload.data);
      product = { product_firebase_id, ...payload.data };
    }

    try {
      //save it to the SQL database
      const { data } = await dispatch('saveProductToDatabase', { endpoint: 'create', data: product });
      //retrieve the identity key (PK) and add it to the netsuite payload
      payload.data.internal_product_id = product.internal_product_id = data.productID;
      //create the product in netsuite
      let netsuiteResponse = {};
      try {
        netsuiteResponse = await dispatch('saveProductToNetsuite', { endpoint: 'create', data: product });
      } catch (error) {
        console.log('Error saving product in Netsuite:', error);
      }

      //once we have the netsuite id we need to update the product in both databases
      payload.data.netsuite_id = product.netsuite_id = netsuiteResponse?.data?.result || '';

      payload.data.modified_at = await dispatch('getCurrentDate');
      if (payload.siteID === 'default') {
        await Promise.all([productService.update(product_firebase_id, payload.data), dispatch('saveProductToDatabase', { endpoint: 'update', data: product })]);
      } else {
        await Promise.all([
          siteSettingsService.update(`${payload.siteID}/products/${product_firebase_id}`, payload.data),
          dispatch('saveProductToDatabase', { endpoint: 'update', data: product })
        ]);
      }

      console.log('returning: ', product_firebase_id);
      return product_firebase_id;
    } catch (error) {
      //if we fail to create the product in netsuite or SQL we delete it from the firestore as well to avoid duplicates
      console.log('Error adding new product: ', error);
      dispatch('deleteProduct', product);
    }
  },
  async editProduct({ dispatch }, payload) {
    const product = { product_firebase_id: payload.id, ...payload.data };

    const filters = await dispatch('getOrderTypeFiltersFromProduct', { product: payload.data });

    if (payload.siteID === 'default') {
      //check if the product has already an ID from the SQL db
      if (!payload.data.internal_product_id) {
        const { data } = await dispatch('saveProductToDatabase', { endpoint: 'create', data: product });
        //add the PK to the netsuite and firestore update payloads
        payload.data.internal_product_id = product.internal_product_id = data.productID;
      }

      //check if product exists in netsuite
      const netsuiteEndpoint = payload.data.netsuite_id ? `update/${payload.data.netsuite_id}` : 'create';
      const netsuiteResponse = await dispatch('saveProductToNetsuite', { endpoint: netsuiteEndpoint, data: product });
      payload.data.netsuite_id = product.netsuite_id = Number(netsuiteResponse?.data?.result || payload.data.netsuite_id);
      //after creating/updating product in netsuite we update the product in both databases

      product.modified_at = await dispatch('getCurrentDate');
      payload.data.modified_at = product.modified_at;

      await dispatch(
        'product_pricing/updateAllDefaultProductPricingByID',
        {
          productID: product.product_firebase_id,
          mergeData: true,
          productPricing: filters
        },
        { root: true }
      );

      await Promise.all([
        productService.update(payload.id, payload.data),
        dispatch('saveProductToDatabase', { endpoint: 'update', data: product }),
        dispatch('updateAllDefaultProductInformationByID', product),

        productPricingService.update(payload.id, filters)
      ]);
    } else {
      if (!payload.isOnlyProductStatusModified) {
        payload.data.is_default = false;
      }

      payload.data.modified_at = await dispatch('getCurrentDate');
      filters.is_default = false;

      await Promise.all([
        siteSettingsService.update(`${payload.siteID}/products/${payload.id}`, payload.data),
        siteSettingsService.update(`${payload.siteID}/product_pricing/${payload.id}`, filters),
        dispatch('saveProductToDatabase', { endpoint: 'update', data: product })
      ]);
    }

    return payload.id;
  },

  async getNewProducts(_context, payload) {
    try {
      if (!payload.linkedProducts?.length) {
        return [];
      }
      const defaultProductsArray = await productService.getByIdArray(payload.linkedProducts);
      const formattedDefaultProductsArray = Object.keys(defaultProductsArray).map(productId => {
        const product = defaultProductsArray[productId];
        product.product_firebase_id = productId;
        return product;
      });

      const siteProductsArray = await siteSettingsService.getProductsArrayBySiteID(payload.siteId, payload.linkedProducts);

      return formattedDefaultProductsArray.filter(
        formattedDefaultProduct => !siteProductsArray.some(siteProduct => siteProduct.product_firebase_id === formattedDefaultProduct.product_firebase_id)
      );
    } catch (error) {
      console.error('Error in getNewProducts:', error);
      throw error;
    }
  },

  async importProducts({ dispatch }, payload) {
    try {
      const productPromises = payload.data.map(async productData => {
        const product_firebase_id = productData.product_firebase_id;
        productData.product_usage = 0;
        productData.is_default = true;
        productData.is_active = true;
        productData.modified_at = await dispatch('getCurrentDate');

        try {
          const pricing = await productPricingService.getById(product_firebase_id);

          if (pricing) {
            pricing.is_default = true;
            siteSettingsService.set(`${payload.siteID}/product_pricing/${product_firebase_id}`, pricing, { merge: false });
          }
        } catch (error) {
          console.log('Error: ', error);
        }

        return siteSettingsService.set(`${payload.siteID}/products/${product_firebase_id}`, productData);
      });

      const productsSaved = await Promise.all(productPromises);
      console.log('[importProducts] products saved successfully: ', productsSaved);
    } catch (error) {
      console.error('[importProducts] error saving products in firestore: ', error);
      throw error;
    }
  },
  async getDuplicatedProducts({ dispatch, getters }, payload) {
    try {
      const selectedProducts = payload.data;

      const existingProducts = await siteSettingsService.getProductsBySiteID(payload.siteID);

      const productMap = new Map();

      selectedProducts.forEach(product => {
        const productId = product.product_firebase_id;
        productMap.set(productId, product);
      });

      const duplicatedProducts = existingProducts.filter(product => {
        const productId = product.product_firebase_id;
        return productMap.has(productId);
      });

      return duplicatedProducts;
    } catch (error) {
      console.log('Error getting duplicated products: ', error);
    }
  },

  deleteProduct(_context, payload) {
    return new Promise((resolve, reject) => {
      try {
        if (payload.siteID === 'default') {
          reject(new Error('Cannot delete default product.'));
          return;
        }

        siteSettingsService.delete(`${payload.siteID}/products/${payload.product_firebase_id}`);
        siteSettingsService.delete(`${payload.siteID}/product_pricing/${payload.product_firebase_id}`);
        console.log(`[deleteProduct] Deleted product with ID ${payload.product_firebase_id} from site ${payload.siteID}`);
        resolve();
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  },

  async massDeleteProducts({ dispatch }, products) {
    try {
      const deletePromises = products.map(product => dispatch('deleteProduct', { product_firebase_id: product.productFirebaseId, siteID: product.siteID }));

      await Promise.all(deletePromises);
      console.log('[massDeleteProduct] All products deleted successfully.');
    } catch (error) {
      console.error('[massDeleteProduct] Error deleting products:', error);
      throw error;
    }
  },

  async countLinkedTemplatesWithProduct(_context, payload) {
    const templates = await siteSettingsService.getTemplatesBySiteID(payload.siteId);

    return templates.reduce((count, template) => (template?.linked_product === payload.productId ? count + 1 : count), 0);
  },
  saveProductToDatabase(_context, { endpoint, data }) {
    try {
      return cloudFunctionsPost(`/products/${endpoint}`, data);
    } catch (error) {
      console.error('[saveProductToDatabase error ]', error);
      throw new Error(`Could not save product service to SQL database. ${error.response?.data?.message || error.message || ''}`);
    }
  },
  async updateAllDefaultProductInformationByID(_context, payload) {
    console.log('[updateAllDefaultProductInformationByID]: ', payload);
    try {
      const productID = payload?.product_firebase_id;
      const sitesRef = dbFS.collection('sites');
      let processedSites = 0;

      const querySnapshot = await sitesRef.get();

      querySnapshot.forEach(async siteDoc => {
        try {
          const siteId = siteDoc.id;
          const productsRef = siteDoc.ref.collection('products');
          console.log('Processing site for product information:', siteId);

          const productsSnapshot = await productsRef.where('product_firebase_id', '==', productID).where('is_default', '==', true).get();

          productsSnapshot.forEach(productDoc => {
            console.log('Updating product information in site:', siteId);
            productDoc.ref.update(payload);
          });

          processedSites++;
          if (processedSites === querySnapshot.size) {
            console.log('All sites processed for product information.');
          }
        } catch (error) {
          console.error('Error processing site:', siteDoc.id, error);
        }
      });
    } catch (error) {
      console.error('Error:', error);
    }
  },

  saveProductToNetsuite(_context, { endpoint, data }) {
    try {
      //we need to do the mapping here since the same endpoint is used for Services as well
      const netsuiteData = {
        externalId: data.product_firebase_id,
        itemId: `${data.name} - ${data.type?.name || ''}`,
        displayName: data.name,
        custitemcategory_id: data.category?.id || '',
        custitemcategory_name: data.category?.name || '',
        custitemfourover_product_id: data.fourover_product_id,
        custiteminternal_product_id: Number(data.internal_product_id),
        custitemis_availability: data.is_availability,
        custitemis_custom: data.is_custom,
        custitemis_customquote: data.is_customQuote,
        custitemis_eddm: data.is_eddm,
        custitemis_eddmprintonly: data.is_eddmPrintOnly,
        custitemis_map: data.is_map,
        custitemis_newmovers: data.is_newMovers,
        custitemis_printprepship: data.is_printprepship,
        custitemis_saturation: data.is_saturation,
        custitemis_targeted: data.is_targeted,
        custitemis_targetedletter: data.is_targetedLetter,
        custitemis_template: data.is_template,
        custitemproduct_firebase_id: data.product_firebase_id,
        custitemproduct_id: Number(data.internal_product_id),
        custitemsalesforce_product_id: data.salesforce_product_id,
        custitemsize_name: `${data.size_x}x${data.size_y}`,
        custitemsize_x: Number(data.size_x),
        custitemsize_y: Number(data.size_y),
        custitemtype_id: data.type?.id || '',
        custitemtype_name: data.type?.name || ''
      };
      console.log('[saveProductToNetsuite payload ]: ', netsuiteData);

      return endpoint === 'create' ? cloudFunctionsPost(`/netsuite/items/${endpoint}`, netsuiteData) : cloudFunctionsPatch(`/netsuite/items/${endpoint}`, netsuiteData);
    } catch (error) {
      console.error('[saveProductToNetsuite error ]', error);
      throw new Error(`Could not save product to Netsuite. ${error.response?.data?.message || error.message || ''}`);
    }
  },
  initProductCounts({ commit }) {
    try {
      return new Promise(resolve => {
        const sitesRef = dbFS.collection('sites');
        let processedSites = 0;
        const productCounts = {};

        sitesRef.onSnapshot(snapshot => {
          snapshot.forEach(siteDoc => {
            const siteId = siteDoc.id;
            const productsRef = siteDoc.ref.collection('products');

            productsRef.onSnapshot(productsSnapshot => {
              productCounts[siteId] = productsSnapshot.size;
              processedSites++;

              if (processedSites === snapshot.size) {
                commit('setProductCounts', productCounts);
                console.log('product counts: ', productCounts);
                resolve(productCounts);
              }
            });
          });
        });
      });
    } catch (error) {
      console.error('Error fetching template numbers:', error);
      throw error;
    }
  },
  getCurrentDate({ commit }) {
    const currentDate = new Date();
    const dateString = currentDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    const timeString = currentDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });

    return `${dateString}, ${timeString}`;
  },
  async exportProducts({ getters, dispatch }, payload) {
    const products = getters.getProducts;
    const productsDataPromises = products.map(async product => {
      let pricing = {};
      if (product.product_firebase_id) {
        try {
          pricing = await productPricingService.getById(product.product_firebase_id);
        } catch (error) {
          console.log(`Product with the id of [${product.product_firebase_id}] doesn't have a pricing!`);
        }
      }

      return {
        firebase_site_id: payload.siteId,
        name: product?.name || '',
        tax_code: product?.tax_code || '',
        netsuite_id: product?.netsuite_id || '',
        size: `${product?.size_x}x${product?.size_y}` || '',
        image_url: product?.image || '',
        salesforce_product_id: product?.salesforce_product_id || '',
        salesforce_pricebook_entry_id: product?.salesforce_pricebook_entry_id || '',
        fourover_product_id: product?.fourover_product_id || '',
        category: product.category?.name || '',
        type: product.type?.name || '',
        product_firebase_id: product.product_firebase_id || '',
        modified_at: product.modified_at || '',
        pricing_object: pricing || {}
      };
    });

    return Promise.all(productsDataPromises)
      .then(async productsData => {
        const csv = await dispatch('convertObjectToCSV', productsData);
        dispatch('downloadCSV', csv);
      })
      .catch(error => {
        console.error('Error exporting products:', error);
        return [];
      });
  },
  convertObjectToCSV(_context, objArray) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let csv = '';

    csv += Object.keys(array[0]).join(',') + '\n';

    array.forEach(item => {
      const row = Object.values(item)
        .map(value => {
          return typeof value === 'object' ? `"${JSON.stringify(value).replace(/"/g, '""')}"` : `"${value}"`;
        })
        .join(',');
      csv += row + '\n';
    });
    return csv;
  },
  downloadCSV(_context, csv) {
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'products.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  },
  async initIntegrations({ commit }) {
    try {
      const response = await cloudFunctionsGet('/integrations');
      const integrations = response.data;
      commit('setIntegrations', integrations);
    } catch (error) {
      console.error('Error fetching integrations:', error);
      throw error;
    }
  },
  async saveProductIntegrations({ commit }, { productId, integrations, siteId }) {
    try {
      const payload = integrations.map(integration => ({
        integration_id: integration.osprey_id,
        firebase_site_id: siteId,
        firebase_product_id: productId,
        external_sku: integration.value
      }));

      const response = await cloudFunctionsPost('/products/integrations', payload);

      const updatedIntegrations = response.data;

      commit('setIntegrations', updatedIntegrations);

      return updatedIntegrations;
    } catch (error) {
      console.error('Error saving product integrations:', error);
      throw error;
    }
  },

  getOrderTypeFiltersFromProduct({ dispatch }, payload) {
    if (!payload.product) {
      console.log('Error getting product filters, product is missing.');
      return null;
    }
    const filters = {
      is_availability: payload.product.is_availability ?? false,
      is_custom: payload.product.is_custom ?? false,
      is_customQuote: payload.product.is_customQuote ?? false,
      is_eddm: payload.product.is_eddm ?? false,
      is_eddmPrintOnly: payload.product.is_eddmPrintOnly ?? false,
      is_inserts: payload.product.is_inserts ?? false,
      is_map: payload.product.is_map ?? false,
      is_newMovers: payload.product.is_newMovers ?? false,
      is_printprepship: payload.product.is_printprepship ?? false,
      is_saturation: payload.product.is_saturation ?? false,
      is_targeted: payload.product.is_targeted ?? false,
      is_targetedLetter: payload.product.is_targetedLetter ?? false,
      is_template: payload.product.is_template ?? false,
      is_customEstimation: payload.product.is_customEstimation ?? false,
      is_inventory: payload.product.is_inventory ?? false
    };

    return payload.isArrayNeeded ? Object.entries(filters).map(([key, value]) => ({ [key]: value })) : filters;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
