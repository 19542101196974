<template>
  <v-card elevation="4" outlined class="mt-4">
    <v-row class="mt-2 px-4"
      ><v-card-title tag="span" class="primary--text">Product Pricing</v-card-title>
      <v-chip v-if="!isSiteDefault" :color="productInfoColor" class="mt-auto mb-auto text-white"> {{ productInfoType }} </v-chip>
    </v-row>
    <ProductPricing v-model="tiers" :product="product" />
    <v-divider class="ma-8" />
    <v-card-title tag="span" class="primary--text">Product Service Pricing</v-card-title>
    <ServicePricing v-model="rawData" :services="services" :tiers="tiers" />
    <v-card-actions v-if="isEditable">
      <v-col class="text-right">
        <v-btn color="primary" @click="savePricing">Save Changes</v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>
<script>
import ProductPricing from './ProductPricing.vue';
import ServicePricing from './ServicePricing.vue';
import { mapGetters } from 'vuex';
const deepEqual = require('deep-equal');

export default {
  components: {
    ProductPricing,
    ServicePricing
  },
  data() {
    return {
      tiers: [],
      rawData: [],
      initialTiers: [],
      initialRawData: [],
      pricing: {}
    };
  },
  computed: {
    ...mapGetters({
      product: 'products/getCurrentProduct',
      services: 'product_services/getProductServices'
    }),
    selectedSite() {
      return this.$route.params.siteId;
    },
    productFirebaseId() {
      return this.$route.params.productId;
    },
    isEditable() {
      return this.$route.name !== 'ViewProduct';
    },
    isDefaultSite() {
      return this.selectedSite === 'default';
    },
    isSaveDisabled() {
      return (
        this.tiers.some(tier => !tier.up_to || !tier.from || tier.price_per_piece === '') ||
        !this.rawData.length ||
        this.rawData.some(service => service.headers.length <= 2 || service.tiers.some(tier => Object.keys(tier).length - 1 < service.headers.length)) ||
        !this.isDataValid(this.rawData)
      );
    },
    isProductDefault() {
      return this.pricing?.is_default;
    },
    isSiteDefault() {
      return this.selectedSite === 'default';
    },
    productInfoColor() {
      return this.isProductDefault ? 'blue darken-2' : 'grey darken-1';
    },
    productInfoType() {
      return this.isProductDefault ? 'Default' : 'Custom';
    }
  },
  watch: {
    tiers: {
      handler() {
        this.$emit('handlePricingTiersChange', deepEqual(this.initialTiers, this.tiers));
      },
      deep: true
    },
    rawData: {
      handler() {
        this.$emit('handlePricingRawDataChange', deepEqual(this.initialRawData, this.rawData));
      },
      deep: true
    }
  },
  async mounted() {
    this.$store.dispatch('products/getProductAndWatch', { productId: this.productFirebaseId, siteId: this.selectedSite });

    this.$store.dispatch('product_services/fetchProductServices');
    await this.generateDataFromPricing();
  },
  methods: {
    async savePricing() {
      this.pricing.is_default = false;
      const productPricing = {};
      productPricing[this.productFirebaseId] = this.buildPricing(this.tiers, this.rawData);
      this.$store.dispatch('showLoader');

      try {
        const orderTypeFilters = await this.$store.dispatch('products/getOrderTypeFiltersFromProduct', { product: this.product, isArrayNeeded: true });

        await this.$store.dispatch('product_pricing/updateProductPricing', {
          id: this.productFirebaseId,
          data: productPricing,
          siteID: this.selectedSite,
          filters: orderTypeFilters
        });
        this.$store.dispatch('setSnackbar', {
          type: 'success',
          message: `Pricing Information saved for product [${this.productFirebaseId}] under Site ID: [${this.selectedSite}] !`
        });
        this.initialRawData = Object.assign([], this.rawData);
        this.$emit('handlePricingRawDataChange', deepEqual(this.initialRawData, this.rawData));
        this.initialTiers = Object.assign([], this.tiers);
        this.$emit('handlePricingTiersChange', deepEqual(this.initialTiers, this.tiers));
      } catch (error) {
        console.error('[savePricing error]', error);
        this.$store.dispatch('showAlert', { type: 'error', message: 'Could not save Pricing Information, please check the console for more details.' });
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },

    isDataValid(rawData) {
      return !rawData.some(service => {
        return service.headers.some(header => {
          return service.tiers.some(tier => {
            return tier[header?.value] === undefined;
          });
        });
      });
    },

    buildPricing(tiers, rawData) {
      const productPricing = {};

      tiers.forEach(tier => {
        const pricingInfo = {
          up_to: Number(tier.up_to) || 0,
          from: Number(tier.from) || 0,
          flat_fee: Number(tier.flat_fee) || 0,
          price_per_piece: Number(tier.price_per_piece) || 0,
          total_weight: Number(tier.total_weight) || 0,
          services: {}
        };

        const servicesData = rawData.map(service => ({
          name: service.name.toLowerCase().replace(/\s+/g, '_'),
          firebase_service_id: service.firebase_service_id,
          headers: service.headers,
          is_full_price: service.is_full_price,
          selected_options: service.tiers.find(({ up_to }) => Number(up_to) === Number(tier.up_to))
        }));

        servicesData.forEach(service => {
          if (service.selected_options) {
            pricingInfo.services[service.name] = {
              firebase_service_id: service.firebase_service_id,
              headers: service.headers,
              is_full_price: service.is_full_price,
              selected_options: { ...service.selected_options }
            };
          }
        });

        const pricingKey = tier.up_to || 0;
        productPricing[pricingKey] = pricingInfo;
      });

      return productPricing;
    },
    async generateDataFromPricing() {
      this.$store.dispatch('showLoader');
      try {
        let productPricing = await this.$store.dispatch(`product_pricing/${this.isDefaultSite ? 'getDefaultProductPricingById' : 'getCustomProductPricingById'}`, {
          siteId: this.selectedSite,
          product_firebase_id: this.productFirebaseId
        });

        if (!productPricing || !Object.keys(productPricing).length) {
          if (!this.isDefaultSite) {
            productPricing = await this.$store.dispatch('product_pricing/getDefaultProductPricingById', { siteId: 'default', product_firebase_id: this.productFirebaseId });
            if (!productPricing) {
              this.tiers.push({ name: 'Total Units', from: 1, up_to: '', price_per_piece: '', flat_fee: '', total_weight: '' });
            }
          } else {
            this.tiers.push({ name: 'Total Units', from: 1, up_to: '', price_per_piece: '', flat_fee: '', total_weight: '' });
          }
        }

        if (productPricing) {
          console.log('productPricing: ', productPricing);
          this.pricing = productPricing;
          for (const [key, pricingInfo] of Object.entries(productPricing)) {
            if (typeof pricingInfo !== 'object') {
              continue;
            }
            this.tiers.push({
              name: 'Total Units',
              up_to: pricingInfo.up_to,
              from: pricingInfo.from,
              flat_fee: pricingInfo.flat_fee,
              price_per_piece: pricingInfo.price_per_piece,
              total_weight: pricingInfo.total_weight
            });

            for (const [serviceName, serviceData] of Object.entries(pricingInfo.services || {})) {
              const existingServiceIndex = this.rawData.findIndex(service => service.name.toLowerCase().replace(/\s+/g, '_') === serviceName);

              if (existingServiceIndex === -1) {
                this.rawData.push({
                  name: serviceName.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase()),
                  firebase_service_id: serviceData.firebase_service_id,
                  is_full_price: serviceData.is_full_price,
                  headers: serviceData.headers,
                  tiers: [
                    {
                      up_to: pricingInfo.up_to,
                      ...serviceData.selected_options
                    }
                  ]
                });
              } else {
                this.rawData[existingServiceIndex].tiers.push({
                  up_to: pricingInfo.up_to,
                  ...serviceData.selected_options
                });
              }
            }
          }
        }
        this.initialRawData = JSON.parse(JSON.stringify(this.rawData));
        this.initialTiers = JSON.parse(JSON.stringify(this.tiers));
      } catch (error) {
        console.error('[generateDataFromPricing error]', error);
        this.$store.dispatch('showAlert', { type: 'error', message: 'Could not load Pricing Information, please check the console for more details.' });
      } finally {
        this.$store.dispatch('hideLoader');
      }
    }
  }
};
</script>
<style>
/* make table cells bordered */
.table-bordered td,
.table-bordered th {
  border-right: thin solid rgb(0 0 0 / 12%) !important;
  border-bottom: thin solid rgb(0 0 0 / 12%) !important;
}

.table-bordered th {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.6);
}
/* imitate disabled style without setting the button disabled*/
.not-allowed {
  opacity: 35%;
  cursor: not-allowed;
}

.text-white {
  color: white !important;
}
</style>
