import { dbFS } from '@/services/firebaseInit';
import httpClient from '@/modules/httpModule';
import { cloudFunctionsPost } from '../../modules/cloud-function-wrapper.js';
import moment from 'moment';
import mapsService from '../../services/mapsService.js';

const state = {
  ldpOrderProcess: [],
  isMediaPlanCreatedSuccessfully: false,
  ldpMapData: null
};

const getters = {
  getLdpOrderProcess(state) {
    return state.ldpOrderProcess;
  },
  getMediaPlanStatus(state) {
    return state.isMediaPlanCreatedSuccessfully;
  },
  getLdpMapData(state) {
    return state.ldpMapData;
  }
};

const mutations = {
  setLdpOrderProcess(state, payload) {
    const verifySteps = state.ldpOrderProcess.filter(process => process.step === payload.step);
    if (verifySteps.length > 0) {
      Object.assign(state.ldpOrderProcess[state.ldpOrderProcess.map(process => process.step)?.indexOf(payload.step)], payload);
    } else {
      state.ldpOrderProcess.push(payload);
    }
  },
  clearLdpOrderProcess(state) {
    state.ldpOrderProcess = [];
  },
  setMediaPlanStatus(state, payload) {
    state.isMediaPlanCreatedSuccessfully = payload;
  },
  setLdpMapData(state, payload) {
    state.ldpMapData = payload;
  }
};

const actions = {
  addLdpOrderLog({ dispatch }, { firebase_order_id, action, data }) {
    return dispatch('logs/addOrderLog', { firebase_order_id, action, data }, { root: true });
  },
  sendRequest({ commit }, payload) {
    return cloudFunctionsPost(`/${payload.endpoint}`, payload.body);
  },
  getOrderRefById({ commit }, orderID) {
    return dbFS.collection('orders').doc(orderID);
  },
  setOrderAttribute({ commit }, payload) {
    return payload.orderRef.set(payload.changedAttr, { merge: true });
  },
  async updateLdpOrderInDatabase({ dispatch }, payload) {
    console.log('..... updateLdpOrderInDatabase ..... ', payload);
    const orderRef = await dispatch('getOrderRefById', payload.orderID);

    return orderRef
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.data();

        payload.preparedObject.forEach(mediaPlanResult => {
          mediaPlanResult.is_map_express = mediaPlanResult.isExpress || false;
          delete mediaPlanResult.isExpress;
        });

        const savedLdpData =
          payload.propertyName === 'media_plans'
            ? data.ldp_order?.[payload.propertyName]?.filter(mediaPlan => mediaPlan.status !== 'error') || null
            : data.ldp_order?.[payload.propertyName]?.filter(ldpBooking => !ldpBooking.message) || null;

        if (savedLdpData) savedLdpData.push(...payload.preparedObject);

        const ldpOrder = {
          [payload.propertyName]: savedLdpData || payload.preparedObject
        };
        console.log('[ LDP ORDER TO UPDATE ]:', ldpOrder);

        return dispatch('setOrderAttribute', { orderRef, changedAttr: { ldp_order: ldpOrder } })
          .then(() => ({ status: 'success' }))
          .catch(error => Promise.reject(`Updating order with LDP data failed! ${error}`));
      })
      .catch(error => Promise.reject(`Getting Order data from database failed: ${error}`));
  },
  checkGeoCodes({ commit }, payload) {
    console.log('[checkGeoCodes payload]: ', payload);

    const startDateYMD = moment(payload.startDate).format('YYYY-MM-DD');
    console.log('Start Date:', startDateYMD);
    const endDateYMD = moment(payload.endDate).format('YYYY-MM-DD');
    console.log('End Date:', endDateYMD);
    const geoCodesString = payload.geoCodes.toString();

    return httpClient.get({
      baseUrl: `https://${payload.envType ? 'emapdatadev' : 'emapdata'}.adc4gis.com`,
      endpoint: 'availability',
      params: {
        ids: geoCodesString,
        startDate: startDateYMD,
        endDate: endDateYMD
      }
    });
  },
  async getMapData({ commit }, payload) {
    try {
      const mapData = await mapsService.getMapData(payload.mapId);
      console.log('[ MapData ]: ', mapData);
      commit('setLdpMapData', mapData);
      commit('setLdpOrderProcess', { step: 1, status: 'success', data: mapData });
      return mapData;
    } catch (error) {
      commit('setLdpOrderProcess', { step: 1, status: 'error', data: error });
      throw error;
    }
  },

  async submitMediaPlan({ commit, dispatch }, payload) {
    console.log('[ Create media plan payload ]:', payload);
    commit('setMediaPlanStatus', false);
    const dateFormats = ['YYYY-MM-DD', 'DD-MMM-YYYY', 'MMMM, DD YYYY HH:mm:ss'];
    let ldpMapData = payload.mapData || null;
    const orderID = payload.ospreyOrderId;
    const stepId = moment(payload.week, dateFormats).format('MM-DD');

    if (!payload.isSubmitAllSelected) {
      commit('clearLdpOrderProcess');
      commit('setLdpOrderProcess', { step: 1, message: `Getting data from map [ ${payload.mapId} ]` });
      ldpMapData = await dispatch('getMapData', { mapId: payload.mapId });
    }

    const geoCodes = ldpMapData.atz_geocodes;
    console.log('[ GeoCodes ]:', geoCodes);

    if (!geoCodes.length) return Promise.reject({ message: `Map with ID ${payload.mapId} does not have geocodes saved! Media plan request is cancelled!` });

    const week = payload.week || ldpMapData.week;
    console.log('[ Selected week from map ]:', week);
    const startDate = moment(week, dateFormats)._d;
    const endDate = moment(week, dateFormats).add(6, 'days')._d;

    const dateRangeForLogs = `${moment(startDate, dateFormats).format('MMM-DD')} - ${moment(endDate, dateFormats).format('MMM-DD')}`;

    await dispatch('addLdpOrderLog', {
      firebase_order_id: payload.firebaseOrderId,
      action: `Creating media plan based on map [${payload.mapId}] started.`,
      data: {
        week: moment(payload.week, dateFormats).format('YYYY-MMM-DD'),
        dateRange: dateRangeForLogs,
        geocodes: ldpMapData.atz_geocodes
      }
    });

    commit('setLdpOrderProcess', {
      step: `2-${stepId}`,
      message: `Checking GeoCodes for week: ${dateRangeForLogs}`
    });

    const geoCodesCheckResult = await dispatch('checkGeoCodes', { envType: payload.envType, startDate, endDate, geoCodes });
    console.log('GeoCodes check response:', geoCodesCheckResult);

    commit('setLdpOrderProcess', {
      step: `2-${stepId}`,
      status: 'success',
      data: { data: geoCodesCheckResult.data, url: geoCodesCheckResult.config.url }
    });

    const atzArrayFromResponse = geoCodesCheckResult.data;

    commit('setLdpOrderProcess', { step: `3-${stepId}`, message: `Preparing GeoCode arrays for week: ${dateRangeForLogs}` });

    await dispatch('addLdpOrderLog', {
      firebase_order_id: payload.firebaseOrderId,
      action: `Checked GeoCodes for week [ ${dateRangeForLogs} ]: `,
      data: {
        atzArrayFromResponse,
        url: geoCodesCheckResult.config.url
      }
    });

    const atzArray = [];
    const weeklyArray = [];
    // 2 obj
    // 1. weekly = true + available = true
    // 2. weekly = false + available = true
    atzArrayFromResponse.forEach((value, index) => {
      if (value.available) {
        value.weekly ? weeklyArray.push(value.code) : atzArray.push(value.code);
      }
    });
    console.log('[ geoCodesChecked atzArray ]:', atzArray);
    console.log('[ geoCodesChecked weeklyArray ]:', weeklyArray);
    const promises = [];

    if (!atzArray.length && !weeklyArray.length) {
      commit('setLdpOrderProcess', { step: `3-${stepId}`, status: 'error', data: 'Unable to create media plan. No available geocode found!' });
      return Promise.reject({
        message: 'Unable to create media plan. No available geocode found!',
        geoCodes: { geoCodes: atzArrayFromResponse, url: geoCodesCheckResult.config.url },
        mailDropId: payload.mailDropId,
        status: 'error'
      });
    } else {
      commit('setLdpOrderProcess', { step: `3-${stepId}`, status: 'success', data: { atzArray, weeklyArray } });
      const mediaPlanRequest = {
        endpoint: 'ldp/submit-media-plan',
        body: {
          map: {
            startDate: week
          }
        }
      };
      commit('setLdpOrderProcess', { step: `4-${stepId}`, message: `Creating media plan for week: ${dateRangeForLogs}` });
      // ldpSubmitMediaPlan >>> 1 = nonWeekly, 0 - weekly
      if (atzArray.length > 0) {
        mediaPlanRequest.body.is_weekly = 1;
        mediaPlanRequest.body.map.geoCodes = atzArray;

        promises.push(
          dispatch('sendRequest', { ...mediaPlanRequest })
            .then(ldpResponse => Promise.resolve({ mediaPlanResult: ldpResponse.data, orderID, envType: payload.envType, geoCodes: atzArray, startDate }))
            .catch(error => {
              commit('setLdpOrderProcess', { step: `4-${stepId}`, status: 'error', data: { message: `Creating media plan from ATZ array failed: ${error}` } });
              return Promise.reject({ message: `Creating media plan from ATZ array failed: ${error}` });
            })
        );
      }
      if (weeklyArray.length > 0) {
        // since the ADC pushed changes to live, this is available in live mode as well
        mediaPlanRequest.body.is_weekly = 0;
        mediaPlanRequest.body.map.geoCodes = weeklyArray;

        promises.push(
          dispatch('sendRequest', { ...mediaPlanRequest })
            .then(ldpResponse => Promise.resolve({ mediaPlanResult: ldpResponse.data, orderID, envType: payload.envType, geoCodes: weeklyArray, startDate }))
            .catch(error => {
              commit('setLdpOrderProcess', { step: `4-${stepId}`, status: 'error', data: { message: `Creating media plan from Weekly array failed: ${error}` } });
              return Promise.reject({ message: `Creating media plan from Weekly array failed: ${error}` });
            })
        );
      }

      return Promise.all(promises)
        .then(async response => {
          const mediaPlanList = response.map(item => {
            return Object.assign(item.mediaPlanResult, {
              geoCodes: item.geoCodes,
              startDate: item.startDate,
              mailDropId: payload.mailDropId,
              isExpress: payload.switchExpress
            });
          });

          commit('setLdpOrderProcess', { step: `4-${stepId}`, status: 'success', data: mediaPlanList });
          await dispatch('addLdpOrderLog', {
            firebase_order_id: payload.firebaseOrderId,
            action: `Media plan result for week: ${dateRangeForLogs}:`,
            data: mediaPlanList
          });

          if (!payload.isSubmitAllSelected) {
            commit('setLdpOrderProcess', { step: `5-${stepId}`, message: 'Updating order data with LDP result' });
            return dispatch('updateLdpOrderInDatabase', {
              orderID: payload.firebaseOrderId,
              propertyName: 'media_plans',
              preparedObject: mediaPlanList
            })
              .then(() => {
                commit('setLdpOrderProcess', { step: `5-${stepId}`, status: 'success' });
                commit('setLdpOrderProcess', { step: `6-${stepId}`, message: `Media Plan created successfully for week: ${dateRangeForLogs}`, status: 'success' });
                commit('setMediaPlanStatus', true);

                return Promise.resolve(mediaPlanList);
              })
              .catch(error => {
                commit('setLdpOrderProcess', { step: `5-${stepId}`, status: 'error', data: { message: `Updating order data with media plan result failed: ${error}` } });
                return Promise.reject({ message: `Updating order with Media Plan data failed: ${error}` });
              });
          }
          return Promise.resolve(mediaPlanList);
        })
        .catch(error => {
          commit('setLdpOrderProcess', { step: `4-${stepId}`, status: 'error', data: { message: `Creating media plan failed: ${JSON.stringify(error)}` } });
          dispatch('addLdpOrderLog', {
            firebase_order_id: payload.firebaseOrderId,
            action: `Creating media plan failed. ${error.message || error}`,
            data: error
          });
          return Promise.reject({
            message: `Creating media plan failed: ${JSON.stringify(error)}`,
            mailDropId: payload.mailDropId,
            isExpress: payload.switchExpress,
            status: 'error'
          });
        });
    }
  },
  async submitLdpOrder({ commit, dispatch }, payload) {
    const specialCharacters = /[`'"!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/g;
    console.log('[ Submit LDP Order payload:', payload);
    const dateFormats = ['YYYY-MM-DD', 'DD-MMM-YYYY', 'MMMM, DD YYYY HH:mm:ss'];

    commit('setMediaPlanStatus', false);
    commit('clearLdpOrderProcess');
    commit('setLdpOrderProcess', { step: `1-${payload.week}`, message: `Getting advertisement date from map` });

    let week = payload.week || '';
    if (!week && payload.mapId) {
      const result = await mapsService.getMapData(payload.mapId);
      week = result.week;
    }
    const convertedDate = moment(week, dateFormats);
    const advertisementDate = convertedDate.isValid() ? convertedDate.format('DD-MMM-YYYY') : moment(advertisementDate.toDate()).format('DD-MMM-YYYY');

    commit('setLdpOrderProcess', { step: `1-${payload.week}`, status: 'success', data: { week, advertisementDate, mapId: payload.mapId } });
    await dispatch('addLdpOrderLog', {
      firebase_order_id: payload.orderData.firebase_order_id,
      action: `Advertisement week and date from map: [ week: ${week}, advertisementDate: ${advertisementDate}]`
    });

    commit('setLdpOrderProcess', { step: `2-${advertisementDate}`, message: `Preparing data for sending the request for week ${advertisementDate}` });

    const clientInvoicePromotion = `${payload.orderData.crm_order_id.toString()} ${moment().format('DD-MM-YYYY')}`;
    const uniqueAdVersionCode = `OBM${payload.orderData.crm_order_id.toString()}`;
    const clientPO = payload.orderData.crm_customer_id.toString();
    const isExpress = payload.switchExpress ? '1' : null;
    let mediaPlanGroupId = null;
    let numMBUs = null;

    if ((typeof payload.mediaPlan === 'object' && Object.keys(payload.mediaPlan).length > 1) || (Array.isArray(payload.mediaPlan) && payload.mediaPlan.length > 0)) {
      mediaPlanGroupId = Array.isArray(payload.mediaPlan) ? payload.mediaPlan.map(item => parseInt(item.mediaPlanGroupId)) : [Number(payload.mediaPlan.mediaPlanGroupId)];
      numMBUs = payload.mediaPlan.numMBUs;
    } else {
      return Promise.reject({ message: `Media plan group ID is missing!` });
    }

    //TODO: AMP-7247 Turn on if the numMBUs is provided by the Valassis API in production
    // if (!numMBUs) {
    //   return Promise.reject({ message: `Number of MBUs is zero in the media plan! Try to change the selected week or the geocode and create new media plans.` });
    // }

    const body = {
      order_type: payload.orderData.order_type,
      firebase_site_id: payload.orderData.site_db_id,
      isExpress: isExpress,
      advertisementDate: advertisementDate,
      crm: {
        orderId: payload.orderData.firebase_order_id
      },
      client: {
        clientName: payload.client.clientName.toUpperCase().replace(specialCharacters, ''),
        clientNameAppearingOnAd: payload.client.clientNameAppearingOnAd.toUpperCase().substring(0, 15).replace(specialCharacters, ''),
        clientPromotion: payload.client.clientPromotion.toUpperCase().substring(0, 30).replace(specialCharacters, ''),
        clientInvoicePromotion,
        clientPO
      },
      mediaPlanGroupId: mediaPlanGroupId,
      uniqueAdVersionCode
    };

    console.log('ldpSubmitOrder body:', body);
    commit('setLdpOrderProcess', { step: `2-${advertisementDate}`, status: 'success', data: body });
    dispatch('addLdpOrderLog', {
      firebase_order_id: payload.orderData.firebase_order_id,
      action: `Submit LDP order for week ${advertisementDate} request:`,
      data: body
    });
    commit('setLdpOrderProcess', { step: `3-${advertisementDate}`, message: `Submitting LDP order for week ${advertisementDate}` });

    return dispatch('sendRequest', { endpoint: 'ldp/submit-order', body })
      .then(response => {
        console.log('ldpSubmitOrder response:', response.data);
        commit('setLdpOrderProcess', { step: `3-${advertisementDate}`, status: 'success', data: response.data });

        const ldbObject = {
          isExpress: isExpress,
          ldpBookingOrderID: response?.data?.ldpBookingOrderID || null,
          ldp_booking_data: response?.data || null
        };
        if (!payload.isSubmitAllSelected && ldbObject.ldpBookingOrderID) {
          commit('setLdpOrderProcess', { step: 4, message: `Updating order data with LDP result` });
          dispatch('addLdpOrderLog', {
            firebase_order_id: payload.orderData.firebase_order_id,
            action: `Submit LDP order for week ${advertisementDate} result:`,
            data: ldbObject
          });

          return dispatch('updateLdpOrderInDatabase', {
            orderID: payload.orderData.firebase_order_id,
            propertyName: 'ldp_bookings',
            preparedObject: [ldbObject]
          })
            .then(() => {
              commit('setLdpOrderProcess', { step: 4, status: 'success', data: ldbObject });
              commit('setLdpOrderProcess', { step: 5, message: 'LDP order has been successfully sent for processing', status: 'success' });
              return Promise.resolve(ldbObject);
            })
            .catch(error => {
              commit('setLdpOrderProcess', { step: 4, status: 'error', data: { message: `Updating LDP order data in database failed: ${error}` } });
              return Promise.reject({ message: `Updating LDP order data in database failed: ${error}` });
            });
        }
        return Promise.resolve([ldbObject]);
      })
      .catch(error => {
        console.log('[ submitLdpOrder ] ERROR:', error);
        commit('setLdpOrderProcess', {
          step: `3-${advertisementDate}`,
          status: 'error',
          data: { message: `Sending request failed: ${JSON.stringify(error?.response?.data || error)}` }
        });
        dispatch('addLdpOrderLog', {
          firebase_order_id: payload.orderData.firebase_order_id,
          action: `Submit LDP order failed: ${JSON.stringify(error?.response?.data || error?.message || error)}`,
          data: error?.response?.data || error
        });
        return Promise.reject({ message: `Sending request failed: ${JSON.stringify(error?.response?.data)}` });
      });
  },
  async deleteLdpDataFromOrder({ dispatch }, payload) {
    console.log('[ Delete LDP data payload ]:', payload);
    const orderRef = await dispatch('getOrderRefById', payload.firebase_order_id);
    return orderRef.get().then(snapshot => {
      const orderData = snapshot.data();
      const ldpResults = {
        media_plans: orderData.ldp_order.media_plans,
        ldp_bookings: orderData.ldp_order.ldp_bookings
      };
      const mediaPlansToUpdate = ldpResults.media_plans.filter(mediaPlan => parseInt(mediaPlan.mailDropId) !== parseInt(payload.data.mailDropId));
      const ldpBookingsToUpdate = ldpResults.ldp_bookings.filter(ldpBooking => parseInt(ldpBooking.ldpBookingOrderID) !== parseInt(payload.data.ldpBookingOrderID));

      return dispatch('setOrderAttribute', {
        orderRef,
        changedAttr: {
          ldp_order: {
            ['media_plans']: mediaPlansToUpdate,
            ['ldp_bookings']: ldpBookingsToUpdate
          }
        }
      })
        .then(() => ({ status: 'success' }))
        .catch(error => Promise.reject(`Updating order with LDP data failed! ${error}`));
    });
  },
  async submitAllMediaPlans({ commit, dispatch, getters }, payload) {
    console.log('[ Submit all delivery weeks payload ]:', payload);
    const mediaPlanPromises = [];
    if (payload.weeks) {
      commit('clearLdpOrderProcess');
      let ldpMapData = getters.getLdpMapData;

      if (!ldpMapData) {
        commit('setLdpOrderProcess', { step: 1, message: `Getting data from map [ ${payload.mapId} ]` });
        ldpMapData = await dispatch('getMapData', { mapId: payload.mapId });
      }
      Object.values(payload.weeks).forEach(week => {
        mediaPlanPromises.push(
          dispatch('submitMediaPlan', {
            envType: payload.envType,
            mapId: payload.mapId,
            mapData: ldpMapData,
            ospreyOrderId: payload.ospreyOrderId,
            firebaseOrderId: payload.firebaseOrderId,
            switchExpress: week.isExpress,
            mailDropId: week.mailDropId,
            week: String(week.date),
            isSubmitAllSelected: true
          })
        );
      });
      return Promise.allSettled(mediaPlanPromises).then(results => {
        commit('setLdpOrderProcess', { step: 5, message: 'Updating order data with LDP result' });
        console.log('[ Submit all MediaPlans Promises results ]:', results);

        const mediaPlansToUpdateInOrder = [];
        results.forEach(result => {
          if (result.value) {
            mediaPlansToUpdateInOrder.push(...result.value);
          } else if (result.reason) {
            mediaPlansToUpdateInOrder.push(result.reason);
          }
        });
        commit('setMediaPlanStatus', true);

        return dispatch('updateLdpOrderInDatabase', {
          orderID: payload.firebaseOrderId,
          propertyName: 'media_plans',
          preparedObject: mediaPlansToUpdateInOrder
        })
          .then(() => {
            commit('setLdpOrderProcess', { step: 5, data: mediaPlansToUpdateInOrder, status: 'success' });
            if (!results.map(result => result.reason).length) {
              commit('setLdpOrderProcess', { step: 6, message: `Media Plans created successfully`, status: 'success' });
            }
            commit('setMediaPlanStatus', true);
            commit('setLdpMapData', null);

            return Promise.resolve(mediaPlansToUpdateInOrder);
          })
          .catch(error => {
            commit('setLdpOrderProcess', { step: 5, status: 'error', data: { message: `Updating order data with media plan result failed: ${error}` } });
            return Promise.reject({ message: `Updating order with Media Plan data failed: ${error}` });
          });
      });
    }
  },
  submitAllLdpOrders({ commit, dispatch }, payload) {
    console.log('[ Submit all LDPs payload ]:', payload);
    const ldpResultPromises = [];
    if (payload.weeks) {
      commit('clearLdpOrderProcess');
      payload.weeks.forEach((week, index) => {
        ldpResultPromises.push(
          dispatch('submitLdpOrder', {
            envType: payload.envType,
            mapId: payload.mapId,
            orderData: payload.orderData,
            ospreyOrderId: payload.orderData.crm_order_id,
            firebaseOrderId: payload.firebaseOrderId,
            switchExpress: week.isExpress || false,
            week: week.date,
            mediaPlan: payload.mediaPlans[index],
            client: payload.client,
            isSubmitAllSelected: true
          })
        );
      });
      return Promise.allSettled(ldpResultPromises).then(results => {
        commit('setLdpOrderProcess', { step: 4, message: `Updating order data with LDP result` });
        console.log('[ Submit all LDP Orders promises results ]:', results);

        const ldpResultsToUpdateInOrder = [];
        results.forEach(result => {
          if (result.value) {
            ldpResultsToUpdateInOrder.push(...result.value);
          } else if (result.reason) {
            ldpResultsToUpdateInOrder.push(result.reason);
          }
        });

        return dispatch('updateLdpOrderInDatabase', {
          orderID: payload.firebaseOrderId,
          propertyName: 'ldp_bookings',
          preparedObject: ldpResultsToUpdateInOrder
        })
          .then(() => {
            commit('setLdpOrderProcess', { step: 4, status: 'success', data: ldpResultsToUpdateInOrder });
            commit('setLdpOrderProcess', { step: 5, message: 'Order submitted successfully', status: 'success' });
            return Promise.resolve(ldpResultsToUpdateInOrder);
          })
          .catch(error => {
            commit('setLdpOrderProcess', { step: 4, status: 'error', data: { message: `Updating LDP order data in database failed: ${error}` } });
            return Promise.reject({ message: `Updating LDP order data in database failed: ${error}` });
          });
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
