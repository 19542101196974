<template>
  <v-card v-if="step?.stepId" class="px-2 mx-5">
    <v-card-title>
      <v-btn icon large @click.native="back()"><v-icon>mdi-chevron-left</v-icon></v-btn>
      {{ capitalize(step.stepId) }}
    </v-card-title>
    <v-card-subtitle class="ml-9">Step ID: {{ step.stepId }}</v-card-subtitle>
    <v-alert id="step-feedback-alert" :value="feedBack.isVisible" :type="feedBack.type" dismissible @input="hideAlert"> {{ feedBack.message }} </v-alert>

    <v-layout class="step-layout">
      <v-divider class="my-2"></v-divider>
      <div id="step-top-section">
        <v-switch v-model="isStepVisible" color="cyan darken-2" :label="switchLabel" :disabled="isSwitchDisabled"></v-switch>
        <div v-if="isArtworkStep">
          <v-switch v-model="isTemplateAvailable" color="cyan darken-2" label="Template Available"></v-switch>
          <v-switch v-model="isVdpEnabled" color="cyan darken-2" label="VDP"></v-switch>
        </div>
        <v-switch v-show="isShowExtrasVisible" v-model="isShowExtras" color="cyan darken-2" :label="showExtrasLabel" :value="isShowExtras"></v-switch>
        <v-switch
          v-show="isMailingListStep || isMapListStep"
          v-model="isSuppressionListShown"
          color="cyan darken-2"
          :label="showSuppressionListLabel"
          :value="isSuppressionListShown"
        ></v-switch>
        <v-switch
          v-if="isMailingListStep || isMapListStep"
          v-model="isSeedListUploadAvailable"
          color="cyan darken-2"
          :label="seedListUploadLabel"
          :value="isSeedListUploadAvailable"
        ></v-switch>
        <v-switch v-if="deliveryCheck" v-model="isDeliveryOptionAvailable" color="cyan darken-2" label="Delivery Option Available"></v-switch>
        <template v-if="isShippingOptionsStep">
          <v-col cols="12" md="4" sm="6">
            <v-switch v-model="isThirdPartyShippingEnabled" color="cyan darken-2" label="Enable 3rd Party Shipping Method"></v-switch>
            <v-text-field v-model="thirdPartyShippingPrice" label="3rd Party Shipping Price" type="number" prefix="$"></v-text-field>
            <v-switch v-model="isCourierShippingEnabled" color="cyan darken-2" label="Enable Courier Shipping Method"></v-switch>
            <v-text-field v-model="courierShippingPrice" label="Courier Shipping Price" type="number" prefix="$"></v-text-field>
            <v-switch v-model="isLocalPickupShippingEnabled" color="cyan darken-2" label="Enable Local Pickup Shipping Method"></v-switch>
            <v-text-field v-model="localPickupShippingPrice" label="Local Pickup Shipping Price" type="number" prefix="$"></v-text-field>
            <v-switch v-model="isWarehouseShippingEnabled" color="cyan darken-2" label="Enable Warehouse Shipping Method"></v-switch>
            <v-text-field v-model="warehouseShippingPrice" label="Warehouse Shipping Price" type="number" prefix="$"></v-text-field>
            <v-switch v-model="isFreeOnBoardAvailable" color="cyan darken-2" label="Enable Free On Board Shipping Method"></v-switch>
            <v-text-field v-model="freeOnBoardShippingPrice" label="Free On Board Shipping Price" type="number" prefix="$"></v-text-field>
            <v-switch v-model="isLocalDelivery" color="cyan darken-2" label="Enable Local Delivery Shipping Method"></v-switch>
            <v-text-field v-model="localDeliveryPrice" label="Local Delivery Shipping Price" type="number" prefix="$"></v-text-field>
          </v-col>
        </template>

        <v-divider v-if="isArtworkStep" class="my-2"></v-divider>
        <v-row v-if="isArtworkStep">
          <v-col cols="12" sm="12">
            <v-alert v-model="isAtLeastOneSelected" type="error" dismissible outlined>
              <span>At least one Option should be selected!</span>
            </v-alert>
            <p class="title">Available Options</p>
            <v-switch v-model="isFullCustomDesign" color="cyan darken-2" label="Full Custom Design"></v-switch>
            <v-switch v-model="isLater" color="cyan darken-2" label="Later"></v-switch>
            <v-switch v-model="isOnlineProofing" color="cyan darken-2" label="Online Proofing"></v-switch>
            <v-switch v-model="isTemplate" color="cyan darken-2" label="Template Available"></v-switch>
            <v-switch v-model="isUpload" color="cyan darken-2" label="Upload"></v-switch>
            <v-switch v-model="isUploadFiles" color="cyan darken-2" label="Upload files"></v-switch>
          </v-col>
        </v-row>

        <v-divider class="my-2"></v-divider>
        <v-row v-if="isCallTrackingStep">
          <v-col cols="12" sm="12">
            <p class="title">Available Options</p>
            <div style="display: flex">
              <v-switch v-model="isCallTracking" color="cyan darken-2" label="Call Tracking"></v-switch>
              <v-text-field
                v-model="callTrackingPrice"
                hide-details="auto"
                type="number"
                :rules="serviceRules(isCallTracking)"
                :disabled="!isCallTracking"
                width="250px"
                class="ml-5 flex-grow-0"
              />
            </div>
            <div style="display: flex">
              <v-switch v-model="isLandingPage" color="cyan darken-2" label="Landing Page"></v-switch>
              <v-text-field
                v-model="landingPagePrice"
                hide-details="auto"
                type="number"
                :rules="serviceRules(isLandingPage)"
                :disabled="!isLandingPage"
                width="250px"
                class="ml-5 flex-grow-0"
              />
            </div>
            <div style="display: flex">
              <v-switch v-model="isQrCodes" color="cyan darken-2" label="QR Codes"></v-switch>
              <v-text-field
                v-model="qrCodesPrice"
                hide-details="auto"
                type="number"
                :rules="serviceRules(isQrCodes)"
                :disabled="!isQrCodes"
                width="250px"
                class="ml-5 flex-grow-0"
              />
            </div>
          </v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>

        <v-row v-show="isMailingListStep">
          <v-col cols="2" sm="2">
            <h3 class="mt-3">Consumer Advanced:</h3>
          </v-col>
          <v-col cols="4" sm="4">
            <span class="title mr-1">$</span>
            <div class="custom-text-filed">
              <v-text-field v-model="consumerAdvanced" regular type="text"></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row v-show="isMailingListStep">
          <v-col cols="2" sm="2">
            <h3 class="mt-3">Consumer Standard:</h3>
          </v-col>
          <v-col cols="4" sm="4">
            <span class="title mr-1">$</span>
            <div class="custom-text-filed">
              <v-text-field v-model="consumerStandard" regular type="text"></v-text-field>
            </div>
          </v-col>
        </v-row>

        <v-layout v-show="isMailingListStep" row mb-3>
          <v-flex xs10 sm10 class="ml-3">
            <v-switch v-model="isSftpUploadEnabled" color="cyan darken-2" label="Enable SFTP upload"></v-switch>
            <v-layout v-if="isSftpUploadEnabled" row wrap class="ml-5">
              <v-flex xs10 sm10>
                <v-text-field v-model="sftpCredentials.address" label="SFTP Host"> </v-text-field>
                <v-text-field v-model="sftpCredentials.port_number" label="SFTP Port"> </v-text-field>
                <v-text-field v-model="sftpCredentials.user" label="Username"> </v-text-field>
                <v-text-field v-model="sftpCredentials.password" label="Password"> </v-text-field>
                <v-text-field v-model="sftpCredentials.folder_name" label="Upload Path"> </v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <div v-if="isMailingListStep">
          <v-divider></v-divider>

          <v-row class="mt-n3">
            <v-col cols="12" md="2">
              <h3 class="step-id mt-3">Mailing List Options</h3>
              <v-row class="ml-1">
                <v-switch v-model="mailingListOptions.order" color="cyan darken-2" label="Show Order"></v-switch>
              </v-row>
              <v-row class="ml-1 mt-n5">
                <v-switch v-model="mailingListOptions.upload" color="cyan darken-2" label="Show Upload"></v-switch>
              </v-row>
              <v-row class="ml-1 mt-n5">
                <v-switch v-model="mailingListOptions.later" color="cyan darken-2" label="Show Later"></v-switch>
              </v-row>
            </v-col>
            <v-col v-if="mailingListOptions.order" cols="12" md="2">
              <v-card class="pl-8 mt-2" outlined color="grey lighten-3">
                <v-card-title class="subtitle-1 ml-n6">Order Mailing List Options</v-card-title>
                <v-row class="mt-n5">
                  <v-switch v-model="orderMailingListOptions.business" color="cyan darken-2" label="Consumer List"></v-switch>
                </v-row>
                <v-row class="mt-n5">
                  <v-switch v-model="orderMailingListOptions.consumer" color="cyan darken-2" label="Business List"></v-switch>
                </v-row>
                <v-row class="mt-n5">
                  <v-switch v-model="orderMailingListOptions.political" color="cyan darken-2" label="Political List"></v-switch>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <div v-if="markup_price != undefined">
          <v-row class="d-flex mt-3">
            <h3 class="step-id ma-2 pl-1">Markup prices by</h3>
            <v-col cols="12" md="2" xl="1" class="mt-n2 ml-n2 mb-n5">
              <v-text-field id="markup_price" v-model="markup_price" type="text" append-outer-icon="mdi-percent" outlined dense> </v-text-field>
            </v-col>
          </v-row>
          <v-divider class="mb-5"></v-divider>
        </div>
      </div>

      <div v-if="isStepContainsMap">
        <v-row class="d-flex">
          <h3 class="step-id ma-3 pl-1">Minimum Map Quantity</h3>
          <v-col cols="12" md="2" xl="1" class="mt-n2 ml-n2 mb-n5">
            <v-text-field v-model="minimumMapQuantity" :rules="[value => (!!value && value >= 1) || 'Number has to be greater than or equal to 1']" type="number" outlined dense>
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider class="mb-4" />

        <v-row class="d-flex">
          <v-col cols="12" sm="12">
            <v-switch v-model="isDownloadEmapCsvEnabledByMapType" color="cyan darken-2" label="Download CSV from Emap by Map Type"></v-switch>
          </v-col>
        </v-row>
        <v-divider class="mb-4" />
      </div>

      <div v-if="isCustomPricingTableVisible">
        <div class="d-flex justify-space-between align-center">
          <h3 class="step-id ml-1 mb-2">LDP Custom Price</h3>
        </div>
        <v-btn color="primary" small class="mb-2" @click="addNewRow">
          <v-icon left>mdi-plus</v-icon>
          Add Row
        </v-btn>
        <v-row class="mb-1">
          <v-col cols="12" md="10" lg="8" xl="6">
            <stamp-data-table :headers="customPricingHeaders" :data="customPricingData" :is-footer-hidden="true" item-key="id">
              <template #[`item.count`]="{ item }">
                <div class="d-flex justify-center align-center py-2">
                  <v-text-field
                    v-model.number="item.min"
                    type="number"
                    dense
                    outlined
                    class="count-input text-center"
                    placeholder="Min"
                    hide-details
                    @blur="validateRange(item)"
                  ></v-text-field>
                  <span class="mx-3 subtitle-1">-</span>
                  <v-text-field
                    v-model.number="item.max"
                    type="number"
                    dense
                    outlined
                    class="count-input text-center"
                    placeholder="Max"
                    hide-details
                    @blur="validateRange(item)"
                  ></v-text-field>
                </div>
              </template>
              <template #[`item.price`]="{ item }">
                <v-text-field v-model.number="item[getPriceField(item)]" prefix="$" type="number" dense outlined class="price-input" hide-details></v-text-field>
              </template>
              <template #[`item.discounted`]="{ item }">
                <v-text-field v-model.number="item.discounted" prefix="$" type="number" dense outlined class="price-input" hide-details></v-text-field>
              </template>
              <template #[`item.express`]="{ item }">
                <v-text-field v-model.number="item.express" prefix="$" type="number" dense outlined class="price-input" hide-details></v-text-field>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="deleteRow(item)"> mdi-delete </v-icon>
              </template>
            </stamp-data-table>
          </v-col>
        </v-row>

        <v-divider class="mb-3" />
      </div>

      <div v-if="step.stepId === 'pieces-number'">
        <h3>Mode of entering quantity</h3>
        <v-radio-group v-model="qtyInputType" :mandatory="false">
          <v-radio label="Input Variable" value="input"></v-radio>
          <v-radio label="Predefined Quantities List" value="list"></v-radio>
        </v-radio-group>
        <v-divider></v-divider>
        <br />
      </div>

      <div v-if="step.stepId === 'digital-direct-mail-plan'">
        <div v-if="isLandingPageVisibilityAvailable">
          <v-switch v-model="isLandingPageVisible" color="cyan darken-2" label="Landing Page Visibility"></v-switch>
          <v-divider></v-divider>
        </div>

        <div v-if="isPlansSetupAvailable">
          <div class="mt-3"><h3>Visibility of the Plan Prices</h3></div>
          <v-switch v-model="isGoodPlanPriceVisible" color="cyan darken-2" label="Price of Good Plan visibility"></v-switch>
          <v-switch v-model="isUltimatePlanPriceVisible" color="cyan darken-2" label="Price of Ultimate Plan visibility"></v-switch>
          <v-switch v-model="isPremiumPlanPriceVisible" color="cyan darken-2" label="Price of Premium Plan visibility"></v-switch>
        </div>

        <div v-if="isPlansStaticSelect" class="pt-3">
          <h3>Change plan price type</h3>
          <v-row>
            <v-col>
              <v-row>
                <span class="ml-4 mt-5 mr-4">Good Plan Price:</span>
                <v-switch v-model="isGoodPlanPriceStatic" color="cyan darken-2" :label="`${isGoodPlanPriceStatic ? 'Static' : 'Dynamic'}`"></v-switch>
              </v-row>
              <v-col v-if="isGoodPlanPriceStatic" cols="12" sm="12" md="10" lg="8" class="ml-n2 mt-n3">
                <v-text-field v-model="goodPlanStaticPrice" type="number" label="Please enter the price of the Good Plan:"></v-text-field>
              </v-col>
            </v-col>
            <v-col>
              <v-row>
                <span class="ml-4 mt-5 mr-4">Ultimate Plan Price:</span>
                <v-switch v-model="isUltimatePlanPriceStatic" color="cyan darken-2" :label="`${isUltimatePlanPriceStatic ? 'Static' : 'Dynamic'}`"></v-switch>
              </v-row>
              <v-col v-if="isUltimatePlanPriceStatic" cols="12" sm="12" md="10" lg="8" class="ml-n2 mt-n3">
                <v-text-field v-model="ultimatePlanStaticPrice" type="number" label="Please enter the price of the Ultimate Plan:"></v-text-field>
              </v-col>
            </v-col>
            <v-col>
              <v-row>
                <span class="ml-4 mt-5 mr-4">Premium Plan Price:</span>
                <v-switch v-model="isPremiumPlanPriceStatic" color="cyan darken-2" :label="`${isPremiumPlanPriceStatic ? 'Static' : 'Dynamic'}`"></v-switch>
              </v-row>
              <v-col v-if="isPremiumPlanPriceStatic" cols="12" sm="12" md="10" lg="8" class="ml-n2 mt-n3">
                <v-text-field v-model="premiumPlanStaticPrice" type="number" label="Please enter the price of the Premium Plan:"></v-text-field>
              </v-col>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </div>

        <div v-if="isPlansCountStaticSelect" class="pt-3">
          <h3>Change plan impression count type</h3>
          <v-row>
            <v-col>
              <v-row>
                <span class="ml-4 mt-5 mr-4">Good Plan Count:</span>
                <v-switch v-model="isGoodPlanCountStatic" color="cyan darken-2" :label="`${isGoodPlanCountStatic ? 'Static' : 'Dynamic'}`"></v-switch>
              </v-row>
              <v-col v-if="isGoodPlanCountStatic" cols="12" sm="12" md="8" lg="8" class="ml-n2 mt-n3">
                <v-text-field v-model="goodPlanStaticCount" type="number" label="Please enter the Count of the Good Plan:"></v-text-field>
              </v-col>
            </v-col>
            <v-col>
              <v-row>
                <span class="ml-4 mt-5 mr-4">Ultimate Plan Count:</span>
                <v-switch v-model="isUltimatePlanCountStatic" color="cyan darken-2" :label="`${isUltimatePlanCountStatic ? 'Static' : 'Dynamic'}`"></v-switch>
              </v-row>
              <v-col v-if="isUltimatePlanCountStatic" cols="12" sm="12" md="8" lg="8" class="ml-n2 mt-n3">
                <v-text-field v-model="ultimatePlanStaticCount" type="number" label="Please enter the Count of the Ultimate Plan:"></v-text-field>
              </v-col>
            </v-col>
            <v-col>
              <v-row>
                <span class="ml-4 mt-5 mr-4">Premium Plan Count:</span>
                <v-switch v-model="isPremiumPlanCountStatic" color="cyan darken-2" :label="`${isPremiumPlanCountStatic ? 'Static' : 'Dynamic'}`"></v-switch>
              </v-row>
              <v-col v-if="isPremiumPlanCountStatic" cols="12" sm="12" md="10" lg="8" class="ml-n2 mt-n3">
                <v-text-field v-model="premiumPlanStaticCount" type="number" label="Please enter the Count of the Premium Plan:"></v-text-field>
              </v-col>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </div>

        <br />
        <h3>Top Content</h3>
        <br />
        <tinymce
          id="topSection"
          v-model="tinyTopSection"
          :other_options="tinymceOptions"
          :toolbar1="tinymceToolbar"
          :plugins="tinymcePlugins"
          @editorInit="e => e.setContent(`${tinyTopSection}`)"
        ></tinymce>

        <v-divider></v-divider>
        <br />
      </div>

      <div v-if="isMapListStep">
        <h3 class="step-id mt-4">Map List Options</h3>
        <v-row class="ml-1">
          <v-switch v-model="mapListOptions.map" color="cyan darken-2" label="Show Map"></v-switch>
        </v-row>
        <v-row class="ml-1 mt-n5">
          <v-switch v-model="mapListOptions.upload" color="cyan darken-2" label="Show Upload"></v-switch>
        </v-row>
        <v-row class="ml-1 mt-n5">
          <v-switch v-model="mapListOptions.later" color="cyan darken-2" label="Show Later"></v-switch>
        </v-row>
        <v-divider class="mb-4"></v-divider>
      </div>

      <div v-if="isListProcessingStep">
        <h3 class="step-id font-weight-regular mt-2">Address Cleaning Services Prices</h3>
        <v-row>
          <v-col cols="6" sm="3" md="2" xl="1" class="pt-4 ml-n5">
            <p class="subtitle-1 font-weight-regular text-end">NCOA Registry:</p>
          </v-col>
          <v-col cols="5" sm="3" md="2" xl="1">
            <v-text-field v-model="listProcessingServices.ncoa" type="number" outlined dense></v-text-field>
          </v-col>
          <v-col cols="6" sm="3" md="2" xl="1" class="pt-4">
            <p class="subtitle-1 font-weight-regular text-end">NCOA & Current:</p>
          </v-col>
          <v-col cols="5" sm="3" md="2" xl="1">
            <v-text-field v-model="listProcessingServices.ncoaCurrent" type="number" outlined dense></v-text-field>
          </v-col>
          <v-col cols="6" sm="3" md="1" xl="1" class="pt-4">
            <p class="subtitle-1 font-weight-regular text-end">Current:</p>
          </v-col>
          <v-col cols="5" sm="3" md="2" xl="1">
            <v-text-field v-model="listProcessingServices.current" type="number" outlined dense></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="mb-3 mt-n5" />
      </div>

      <div v-if="isSubscriptionSelectorStep">
        <div v-if="availableSubscriptions" class="mb-3">
          <v-row class="ml-0 mt-4">
            <h3>Set available subscription options</h3>
          </v-row>
          <v-row v-for="(option, index) in availableSubscriptions" :key="index" class="d-flex align-center ml-0 pt-2 mb-n7" no-gutters>
            <v-col cols="12" sm="5" lg="3" xl="2">
              <v-switch :key="index" v-model="selectedSubscriptions" color="cyan darken-2" :label="option.name" :value="option.id" multiple></v-switch>
            </v-col>
            <v-col cols="10" sm="3" lg="2" xl="1" class="d-flex align-center justify-center pt-3">
              <p class="subtitle-1 font-weight-regular grey--text text--darken-3 mr-2">Discount:</p>
              <v-text-field
                :key="index"
                v-model.number="availableSubscriptions[index].discount"
                :value="option.discount"
                type="number"
                :rules="discountValueRule"
                outlined
                dense
                class="pt-2"
                append-outer-icon="mdi-percent"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-divider class="mt-7" />
        </div>
      </div>

      <!-- ---- ---- ---- ------ ----- ---- ----- ------ ---- ---- ---- ---- ---- -->
      <div><h3>Help Text(s)</h3></div>
      <br />

      <div v-if="isString">
        <v-textarea v-model="editedHelpText" name="input-7-1" filled label="Help Text" auto-grow :value="editedHelpText"></v-textarea>
      </div>

      <div v-for="(texts, i) in editedHelpText" v-else :key="i">
        <div v-if="isSimpleText(texts)">
          <div>Text for: {{ i }}</div>
          <v-textarea v-model="editedHelpText[i]" name="input-7-1" filled label="Help Text" auto-grow :value="texts"></v-textarea>
        </div>

        <div v-if="texts.type !== 'undefined' && isSimpleText(texts.type)">
          <div>Text for: {{ texts.type }}</div>
          <v-textarea v-model="texts.text" name="input-7-1" filled label="Help Text" auto-grow :value="texts.text"></v-textarea>
        </div>

        <div v-for="(item, j) in texts" v-else :key="j">
          <div>Text for: {{ item.type }}</div>
          <v-textarea v-model="item.text" name="input-7-1" filled label="Help Text" auto-grow :value="item.text"></v-textarea>
        </div>
      </div>

      <div><h3>Step Text(s)</h3></div>
      <br />

      <div v-for="(text, i) in editedStepTexts" :key="i">
        <div v-if="hasAttributes(text) && i !== 'topContent'">
          <div v-for="(item, j) in text" :key="j">
            <div v-if="hasAttributes(item)">
              <div v-for="(obj, k) in item" :key="k">
                <div v-if="hasAttributes(obj)">
                  <div v-for="(string, l) in obj" :key="l">
                    <div>Text for: {{ formattedText(i) }} - {{ formattedText(j) }} - {{ k + 1 }}. {{ formattedText(l) }}</div>
                    <v-textarea v-model="editedStepTexts[i][j][k][l]" name="input-7-1" filled label="Step Text" auto-grow :value="string"></v-textarea>
                  </div>
                </div>
                <div v-else-if="k">
                  <div>Text for: {{ formattedText(i) }} - {{ formattedText(j) }} - {{ formattedText(k) }}</div>
                  <v-textarea v-model="editedStepTexts[i][j][k]" name="input-7-1" filled label="Step Text" auto-grow :value="item"></v-textarea>
                </div>
              </div>
            </div>
            <div v-else>
              <div>Text for: {{ formattedText(i) }} - {{ formattedText(j) }}</div>
              <v-textarea v-model="editedStepTexts[i][j]" name="input-7-1" filled label="Step Text" auto-grow :value="item"></v-textarea>
            </div>
          </div>
        </div>

        <div v-else-if="i !== 'topContent'">
          <div>Text for: {{ formattedText(i) }}</div>
          <v-textarea v-model="editedStepTexts[i]" name="input-7-1" filled label="Step Text" auto-grow :value="text"></v-textarea>
        </div>
      </div>

      <v-card-actions>
        <v-btn color="info" class="mr-3" @click="onSave">Save</v-btn>
        <v-btn color="red lighten-2" dark @click="onResetStep">Reset</v-btn>
      </v-card-actions>
      <ConfirmationDialog
        v-model="isResetStepDialog"
        title="Reset Confirmation"
        text="Are you sure you want to reset step settings?"
        @onClose="onResetStepRejected"
        @onConfirm="onResetStepConfirmed"
      />
    </v-layout>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import tinymce from 'vue-tinymce-editor';
import DataTable from '@/components/common/DataTable.vue';
import ConfirmationDialog from '../dialog/ConfirmationDialog.vue';

export default {
  components: {
    ConfirmationDialog,
    tinymce,
    'stamp-data-table': DataTable
  },
  data() {
    return {
      selectedSiteId: this.$route.params.siteId,
      selectedStep: this.$route.params.step,
      orderProcessSelected: this.$route.params.order,
      helpText: '',
      editedHelpText: [],
      editedStepTexts: {},
      isResetStepDialog: false,
      isStepVisible: true,
      isShowExtras: true,
      isGoodPlanPriceVisible: true,
      isUltimatePlanPriceVisible: true,
      isPremiumPlanPriceVisible: true,
      isGoodPlanPriceStatic: false,
      isUltimatePlanPriceStatic: false,
      isPremiumPlanPriceStatic: false,
      goodPlanStaticPrice: 0,
      ultimatePlanStaticPrice: 0,
      premiumPlanStaticPrice: 0,
      isGoodPlanCountStatic: false,
      isUltimatePlanCountStatic: false,
      isPremiumPlanCountStatic: false,
      goodPlanStaticCount: 0,
      ultimatePlanStaticCount: 0,
      premiumPlanStaticCount: 0,
      isLandingPageVisible: true,
      isSuppressionListShown: true,
      isSeedListUploadAvailable: false,
      markup_price: '',
      consumerAdvanced: 0,
      consumerStandard: 0,
      isSftpUploadEnabled: false,
      sftpCredentials: {
        address: '',
        port_number: '',
        user: '',
        password: '',
        folder_name: ''
      },
      isTemplateAvailable: false,
      options: {},
      isFullCustomDesign: false,
      isLater: false,
      isCallTracking: false,
      callTrackingPrice: 0,
      callTrackingHelpText: '',
      isLandingPage: false,
      landingPagePrice: 0,
      landingPageHelpText: '',
      isQrCodes: false,
      qrCodesPrice: 0,
      qrCodesHelpText: '',
      isOnlineProofing: false,
      isUpload: false,
      isUploadFiles: false,
      isTemplate: false,
      isVdpEnabled: false,
      countTrueValues: 0,
      initialcountTrueValues: 0,
      isAtLeastOneSelected: false,
      isDeliveryOptionAvailable: true,
      qtyInputType: '',
      tinymceOptions: {
        height: 200
      },
      tinymceToolbar:
        'undo redo | formatselect | bold italic strikethrough |' +
        'forecolor backcolor link | alignleft aligncenter alignright alignjustify |' +
        'numlist bullist outdent indent | removeformat | numlist bullist outdent indent',
      tinymcePlugins: ['textcolor link image code wordcount paste colorpicker lists'],
      tinyTopSection: '',
      mailingListOptions: {
        order: false,
        upload: false,
        later: false
      },
      mapListOptions: {
        map: false,
        upload: false,
        later: false
      },
      orderMailingListOptions: {
        business: false,
        consumer: false,
        political: false
      },
      feedBack: {
        isVisible: false,
        type: 'info',
        message: ''
      },
      isThirdPartyShippingEnabled: false,
      isLocalPickupShippingEnabled: false,
      isCourierShippingEnabled: false,
      isWarehouseShippingEnabled: false,
      isFreeOnBoardAvailable: false,
      isLocalDelivery: false,
      thirdPartyShippingPrice: 0,
      courierShippingPrice: 0,
      localPickupShippingPrice: 25,
      warehouseShippingPrice: 0,
      freeOnBoardShippingPrice: 0,
      localDeliveryPrice: 50,
      listProcessingServices: {
        current: 0,
        ncoa: 30,
        ncoaCurrent: 35
      },
      availableSubscriptions: [],
      selectedSubscriptions: [],
      minimumMapQuantity: 1,
      customPricingHeaders: [
        { text: 'Count', value: 'count', sortable: false, width: '300' },
        { text: 'Price', value: 'price', sortable: false, align: 'center' },
        { text: 'Discounted (LDP)', value: 'discounted', sortable: false, align: 'center' },
        { text: 'Express (LDP Rush)', value: 'express', sortable: false, align: 'center' },
        { text: '', value: 'actions', sortable: false, align: 'center' }
      ],
      customPricingData: [],
      discountValueRule: [value => (!!value && value >= 1 && value <= 99) || 'Number has to be a value between 1 and 99'],
      isDownloadEmapCsvEnabledByMapType: false,
      artworkOptionsMapping: {
        fullCustomDesign: 'isFullCustomDesign',
        later: 'isLater',
        onlineProofing: 'isOnlineProofing',
        upload: 'isUpload',
        template: 'isTemplate',
        upload_files: 'isUploadFiles'
      }
    };
  },
  computed: {
    isMaxDisabled() {
      return function (index) {
        return index === this.customPricingData.length - 1;
      };
    },

    isSwitchDisabled() {
      return [
        'categories',
        'products',
        'quantity',
        'map-tool',
        'save-map',
        'size-selector',
        'mailing-list-options',
        'campaign-name',
        'templates',
        'audience-options',
        'save-map-qty'
      ].includes(this.step.stepId);
    },
    step() {
      return this.$store.getters.getSelectedStep;
    },
    ...mapGetters('site', {
      order: 'getSelectedSite',
      selectedSite: 'getSelectedSite'
    }),
    ...mapGetters({
      orderSettings: 'getOrderSettings'
    }),
    switchLabel() {
      return `Step visibility in the ${this.order} order`;
    },
    showExtrasLabel() {
      return 'Show Extra Step';
    },
    showSuppressionListLabel() {
      return 'Show Suppression List';
    },
    seedListUploadLabel() {
      return 'Show Seed List Upload';
    },
    helpTextList() {
      if (this.step?.helpText && typeof this.step.helpText === 'object') {
        const array = [];
        for (let i in this.step.helpText) {
          if (this.isSimpleText(this.step.helpText[i])) {
            array.push({ text: this.step.helpText[i], type: i });
          } else if (typeof this.step.helpText[i] === 'object' && this.step.stepId !== 'stock-coating' && this.step.stepId !== 'bindery-option' && this.step.stepId !== 'artwork') {
            const items = [];

            for (let j in this.step.helpText[i]) {
              items.push({
                type: j,
                text: this.step.helpText[i][j]
              });
            }
            array.push(items);
          } else {
            array.push(this.step.helpText[i]);
          }
        }
        console.log('HelpText Array: ', array);
        return array;
      }
      console.log('Simple HelpText: ', this.step?.helpText);
      return this.step?.helpText;
    },
    stepTexts() {
      return this.step?.stepTexts;
    },
    isString() {
      return typeof this.helpTextList === 'string';
    },
    isShowExtrasVisible() {
      return this.step.stepId === 'size-selector';
    },
    isMailingListStep() {
      return this.step.stepId === 'mailing-list-options';
    },
    isMapToolStep() {
      return this.step.stepId === 'map-tool';
    },
    isMapListStep() {
      return this.step.stepId === 'map-list-options';
    },
    isMapSelectorStep() {
      return this.step.stepId === 'map-selector';
    },
    isAudienceSelectorStep() {
      return this.step.stepId === 'audience-options';
    },
    isMarkupPriceVisible() {
      return this.step.hasOwnProperty('markup_price') && (this.isMapToolStep || this.isMapListStep);
    },
    isStepContainsMap() {
      return this.isMapToolStep || this.isMapListStep || this.isMapSelectorStep || this.isAudienceSelectorStep;
    },
    isArtworkStep() {
      return this.step.stepId === 'artwork';
    },
    isDigitalUpsellStep() {
      return this.step.stepId === 'digital-direct-mail-plan';
    },
    isPlansSetupAvailable() {
      return (
        this.step.plans !== undefined &&
        this.step.plans.isGoodPlanPriceVisible !== undefined &&
        this.step.plans.isUltimatePlanPriceVisible !== undefined &&
        this.step.plans.isPremiumPlanPriceVisible !== undefined
      );
    },
    isLandingPageVisibilityAvailable() {
      return this.step.isLandingPageVisible !== undefined;
    },
    isPlansStaticSelect() {
      return (
        this.step.plans !== undefined &&
        this.step.plans.isGoodPriceStatic !== undefined &&
        this.step.plans.isUltimatePriceStatic !== undefined &&
        this.step.plans.isPremiumPriceStatic !== undefined
      );
    },
    isPlansCountStaticSelect() {
      return (
        this.step.plans !== undefined &&
        this.step.plans.isGoodQtyStatic !== undefined &&
        this.step.plans.isPremiumQtyStatic !== undefined &&
        this.step.plans.isUltimateQtyStatic !== undefined
      );
    },
    deliveryCheck() {
      return (
        this.step.stepId === 'delivery' &&
        (this.orderProcessSelected === 'eddm' ||
          this.orderProcessSelected === 'targeted' ||
          this.orderProcessSelected === 'eddmPrintOnly' ||
          this.orderProcessSelected === 'saturation')
      );
    },
    isMailingListSavePermitted() {
      return this.isMailingListStep && Object.values(this.orderMailingListOptions).indexOf(true) !== -1;
    },
    isShippingOptionsStep() {
      return this.step.stepId === 'shipping-options';
    },
    isListProcessingStep() {
      return this.step.stepId === 'list-processing';
    },
    isSubscriptionSelectorStep() {
      return this.step.stepId === 'subscription-selector';
    },
    isCustomPricingTableVisible() {
      return this.isMapToolStep && this.orderProcessSelected === 'availability';
    },
    isCallTrackingStep() {
      return this.step.stepId === 'call-tracking-service';
    }
  },
  watch: {
    helpTextList() {
      this.editedHelpText = this.helpTextList;
    },
    stepTexts() {
      this.editedStepTexts = this.stepTexts;
    },
    isFullCustomDesign(value) {
      this.changeInitialCountTrueValues(value);
    },
    isLater(value) {
      this.changeInitialCountTrueValues(value);
    },
    isOnlineProofing(value) {
      this.changeInitialCountTrueValues(value);
    },
    isUpload(value) {
      this.changeInitialCountTrueValues(value);
    },
    isTemplate(value) {
      this.changeInitialCountTrueValues(value);
    }
  },
  async created() {
    await this.initStepData();
    console.log('---step', this.step);
    this.isTemplateAvailable = this.step.isTemplateAvailable;
    this.isVdpEnabled = this.step.isVdpEnabled || false;
    this.isStepVisible = this.step.visible;
    this.editedHelpText = this.helpTextList;

    this.editedStepTexts = this.stepTexts;
    this.isShowExtras = this.step.show_extras;
    this.isSuppressionListShown = this.step.show_suppression_list;
    this.isSeedListUploadAvailable = this.step?.seed_list_upload_enabled || false;
    // only for mailing list options (targeted)
    this.consumerAdvanced = this.step.consumer_advanced;
    this.consumerStandard = this.step.consumer_standard;

    //only for Eddm print only - pieces-number
    this.qtyInputType = this.step.qty_input_type;

    // eddm, eddm printonly, targeted
    this.tinyTopSection = this.step?.stepTexts.topContent || '';

    this.markup_price = this.isMarkupPriceVisible ? this.step.markup_price : undefined;

    if (this.isMailingListStep) {
      this.sftpCredentials = this.step.sftp_credentials || {
        address: '',
        port_number: '',
        user: '',
        password: '',
        folder_name: ''
      };

      this.isSftpUploadEnabled = this.step.sftp_upload_enabled || false;

      this.mailingListOptions = this.step.options;
      if (this.step.order_mailing_list_options) {
        this.orderMailingListOptions = this.step.order_mailing_list_options;
      }
    }
    this.options = this.step.options;
    if (this.isArtworkStep) {
      Object.entries(this.options).forEach(([key, value]) => {
        if (value) {
          this.countTrueValues++;
        }
        const mappedKey = this.artworkOptionsMapping[key];
        if (mappedKey) {
          this[mappedKey] = value;
        }
      });
    }
    if (this.isCallTrackingStep) {
      for (let i = 0; i < this.options.length; i++) {
        switch (this.options[i].id) {
          case 'call-tracking':
            this.isCallTracking = this.options[i].visible;
            this.callTrackingPrice = this.options[i].price;
            break;
          case 'landing-page':
            this.isLandingPage = this.options[i].visible;
            this.landingPagePrice = this.options[i].price;
            break;
          case 'qr-code':
            this.isQrCodes = this.options[i].visible;
            this.qrCodesPrice = this.options[i].price;
            break;
        }
      }
    }

    if (this.deliveryCheck) {
      this.isDeliveryOptionAvailable = this.step.isDeliveryOptionAvailable;
    }

    if (this.isDigitalUpsellStep && this.isLandingPageVisibilityAvailable) {
      this.isLandingPageVisible = this.step.isLandingPageVisible;
    }

    // Init the values of the prices visibility - Only for digital-direct-mail-plan step
    if (this.isDigitalUpsellStep && this.isPlansSetupAvailable) {
      this.isGoodPlanPriceVisible = this.step.plans.isGoodPlanPriceVisible;
      this.isUltimatePlanPriceVisible = this.step.plans.isUltimatePlanPriceVisible;
      this.isPremiumPlanPriceVisible = this.step.plans.isPremiumPlanPriceVisible;
    }

    if (this.isDigitalUpsellStep && this.isPlansStaticSelect) {
      this.isGoodPlanPriceStatic = this.step.plans.isGoodPriceStatic;
      this.goodPlanStaticPrice = this.step.plans.goodPrice;
      this.isUltimatePlanPriceStatic = this.step.plans.isUltimatePriceStatic;
      this.ultimatePlanStaticPrice = this.step.plans.ultimatePrice;
      this.isPremiumPlanPriceStatic = this.step.plans.isPremiumPriceStatic;
      this.premiumPlanStaticPrice = this.step.plans.premiumPrice;
    }

    if (this.isDigitalUpsellStep && this.isPlansCountStaticSelect) {
      this.isGoodPlanCountStatic = this.step.plans.isGoodQtyStatic;
      this.goodPlanStaticCount = this.step.plans.goodQty;
      this.isUltimatePlanCountStatic = this.step.plans.isUltimateQtyStatic;
      this.ultimatePlanStaticCount = this.step.plans.ultimateQty;
      this.isPremiumPlanCountStatic = this.step.plans.isPremiumQtyStatic;
      this.premiumPlanStaticCount = this.step.plans.premiumQty;
    }

    if (this.isMapToolStep) {
      this.customPricingData = this.step.custom_price_table || [];
    }

    if (this.isMapListStep) {
      this.mapListOptions = this.step.options;
    }

    if (this.isStepContainsMap) {
      this.minimumMapQuantity = this.step.minimumQty || 1;
      this.isDownloadEmapCsvEnabledByMapType = this.step.download_emap_csv_enabled_by_map_type || false;
    }

    if (this.isShippingOptionsStep) {
      this.isThirdPartyShippingEnabled = this.step.third_party_shipping_method_enabled;
      this.isLocalPickupShippingEnabled = this.step?.local_pickup_shipping_method_enabled ?? false;
      this.isCourierShippingEnabled = this.step?.courier_shipping_method_enabled ?? false;
      this.isWarehouseShippingEnabled = this.step?.warehouse_shipping_method_enabled ?? false;
      this.isFreeOnBoardAvailable = this.step?.free_on_board_shipping_method_enabled ?? false;
      this.isLocalDelivery = this.step?.local_delivery_shipping_method_enabled ?? false;
      this.thirdPartyShippingPrice = this.step?.third_party_shipping_price ?? 0;
      this.localPickupShippingPrice = this.step?.local_pickup_shipping_price ?? 25;
      this.courierShippingPrice = this.step?.courier_shipping_price ?? 0;
      this.warehouseShippingPrice = this.step?.warehouse_shipping_price ?? 0;
      this.freeOnBoardShippingPrice = this.step?.free_on_board_shipping_price ?? 0;
      this.localDeliveryPrice = this.step?.local_delivery_shipping_price ?? 50;
    }
    if (this.isListProcessingStep && this.step.listProcessingServices) {
      this.listProcessingServices = this.step.listProcessingServices;
    }

    if (this.isSubscriptionSelectorStep) {
      if (this.step.subscription_options) {
        Object.keys(this.step.subscription_options).map(key => {
          this.selectedSubscriptions.push(this.step.subscription_options[key].is_enabled ? this.step.subscription_options[key].id : null);
          this.availableSubscriptions.push(this.step.subscription_options[key]);
        });
      }
    }
  },
  methods: {
    addNewRow() {
      const lastRow = this.customPricingData[this.customPricingData.length - 1];
      const lastRowIndex = this.customPricingData.length - 1;

      if (lastRowIndex >= 0) {
        const lastRowMin = lastRow.min;
        if ((lastRowMin && lastRow.max === null) || lastRow.max === 0) {
          lastRow.max = lastRowMin * 2 - 1;
        }

        this.customPricingData.push({
          min: this.customPricingData[this.customPricingData.length - 1].max ? this.customPricingData[this.customPricingData.length - 1].max + 1 : null,
          max: null,
          price: 0,
          discounted: 0,
          express: 0
        });
      } else {
        this.customPricingData.push({
          min: 0,
          max: 999,
          price: 0,
          discounted: 0,
          express: 0
        });
      }
    },

    deleteRow(item) {
      const index = this.customPricingData.findIndex(row => row.max === item.max && row.min === item.min);
      if (index > -1) {
        this.customPricingData.splice(index, 1);
      }
    },

    validateRange(item) {
      if (!this.customPricingData.length) return;

      const currentIndex = this.customPricingData.findIndex(row => row === item);
      const isFirstItem = currentIndex === 0;
      const isLastItem = currentIndex === this.customPricingData.length - 1;

      if (isFirstItem && item.min) {
        item.min = 0;
      }

      if (isLastItem) {
        item.max = null;
        return;
      }

      const prevItem = !isFirstItem ? this.customPricingData[currentIndex - 1] : null;
      const nextItem = !isLastItem ? this.customPricingData[currentIndex + 1] : null;

      if (item.max && item.min >= item.max) {
        item.max = item.min + 1;
      }

      if (prevItem && prevItem.max && item.min !== prevItem.max + 1) {
        item.min = prevItem.max + 1;
      }

      if (nextItem && item.max && nextItem.min !== item.max + 1) {
        nextItem.min = item.max + 1;
      }
    },

    async initStepData() {
      try {
        this.$store.commit('site/setSelectedOrder', this.orderProcessSelected);
        await this.$store.dispatch('loadOrderSettingById', this.selectedSiteId);
        this.$store.commit(
          'setSelectedStep',
          this.orderSettings[this.selectedSiteId].orderProcesses[this.orderProcessSelected].steps.find(step => step.stepId === this.selectedStep)
        );
        await this.$store.dispatch('site/getSiteDetailsInfo', { key: this.selectedSiteId });
        this.$store.commit('site/setSelectedSite', this.selectedSiteId);
      } catch (error) {
        console.error('An error occurred while initializing step data:', error);
      }
    },

    back() {
      this.$router.back();
    },
    async onSave() {
      console.log('Save step: ', this.step);
      const helpText = this.editedHelpText;

      const config = {
        stepId: this.step.stepId,
        visible: this.isStepVisible,
        stepTexts: this.stepTexts
      };
      if (this.isArtworkStep) {
        config.isTemplateAvailable = this.isTemplateAvailable;
        config.isVdpEnabled = this.isVdpEnabled;
      }
      if (this.isShowExtrasVisible) {
        const showExtras = this.isShowExtras || false;
        config.show_extras = showExtras;
      }

      if (this.isMailingListStep) {
        const isSuppressionListShown = this.isSuppressionListShown || false;
        const consumerAdvanced = this.consumerAdvanced ? parseFloat(this.consumerAdvanced) : 0;
        const consumerStandard = this.consumerStandard ? parseFloat(this.consumerStandard) : 0;
        config.consumer_advanced = consumerAdvanced;
        config.consumer_standard = consumerStandard;
        config.show_suppression_list = isSuppressionListShown;
        config.seed_list_upload_enabled = this.isSeedListUploadAvailable;
        config.sftp_upload_enabled = this.isSftpUploadEnabled;
        config.sftp_credentials = this.sftpCredentials || {
          address: '',
          port_number: '',
          user: '',
          password: '',
          folder_name: ''
        };

        if (this.orderMailingListOptions) {
          if (this.isMailingListSavePermitted) {
            config.order_mailing_list_options = this.orderMailingListOptions;
          } else {
            this.showAlert('error', 'You have to turn on at least one Order Mailing List option!');
            return;
          }
        }

        if (this.checkMapAndMailListSwitches()) {
          config.options = this.mailingListOptions;
        } else {
          this.showAlert('error', 'You have to set at least one list option to show!');
          return;
        }
      }

      if (this.isMarkupPriceVisible) {
        const markupPrice = this.markup_price || '';
        config.markup_price = markupPrice;
      }

      if (this.isMapListStep) {
        config.show_suppression_list = this.isSuppressionListShown || false;
        config.seed_list_upload_enabled = this.isSeedListUploadAvailable || false;
        if (this.checkMapAndMailListSwitches()) {
          config.options = this.mapListOptions;
        } else {
          this.showAlert('error', 'You have to set at least one list option to show!');
          return;
        }
      }

      if (this.isCustomPricingTableVisible) {
        config.custom_price_table = this.customPricingData;
      }

      if (this.isStepContainsMap) {
        config.minimumQty = parseInt(this.minimumMapQuantity) >= 0 ? parseInt(this.minimumMapQuantity) : 1;
        config.download_emap_csv_enabled_by_map_type = this.isDownloadEmapCsvEnabledByMapType || false;
      }

      if (this.isArtworkStep) {
        Object.entries(this.options).forEach(([key]) => {
          const mappedKey = this.artworkOptionsMapping[key];
          if (mappedKey) {
            this.options[key] = this[mappedKey];
          }
        });
      }

      if (this.isCallTrackingStep) {
        for (let i = 0; i < this.options.length; i++) {
          switch (this.options[i].id) {
            case 'call-tracking':
              this.options[i].visible = this.isCallTracking;
              if (this.callTrackingPrice > 0) {
                this.options[i].price = this.callTrackingPrice;
              } else {
                this.options[i].price = 0;
                this.callTrackingPrice = 0;
              }
              break;
            case 'landing-page':
              this.options[i].visible = this.isLandingPage;
              if (this.landingPagePrice > 0) {
                this.options[i].price = this.landingPagePrice;
              } else {
                this.options[i].price = 0;
                this.landingPagePrice = 0;
              }
              break;
            case 'qr-code':
              this.options[i].visible = this.isQrCodes;
              if (this.qrCodesPrice > 0) {
                this.options[i].price = this.qrCodesPrice;
              } else {
                this.options[i].price = 0;
                this.qrCodesPrice = 0;
              }
              break;
          }
        }

        config.options = this.options;
      }

      if (this.initialcountTrueValues > 0) {
        config.options = this.options;
      }

      switch (this.step.stepId) {
        case 'stock-coating':
          config.helpText = {
            coating: helpText[0],
            paper: helpText[1]
          };
          break;
        case 'postage':
          config.helpText = {
            another: helpText[0].text,
            firstClass: helpText[1].text,
            nonProfit: helpText[2].text,
            saturation: helpText[3].text,
            standard: helpText[4].text
          };
          break;
        case 'delivery':
          config.helpText = {
            drops: helpText[0].text,
            times: helpText[1].text
          };
          break;
        case 'list-processing':
          config.helpText = {
            duplicateRemoval: {
              just_addresses: helpText[0][0].text,
              name_address: helpText[0][1].text,
              no_removal: helpText[0][2].text
            },
            listProcessing: {
              current: helpText[1][0].text,
              ncoa: helpText[1][1].text,
              ncoa_current: helpText[1][2].text
            }
          };
          break;
        case 'mailing-list-options':
        case 'map-list-options':
        case 'call-tracking-service':
          config.helpText = helpText.reduce((acc, item) => {
            acc[item.type] = item.text;
            return acc;
          }, {});
          break;
        default:
          config.helpText = this.editedHelpText;
      }
      if (this.deliveryCheck) {
        config.isDeliveryOptionAvailable = this.isDeliveryOptionAvailable;
      }
      if (this.step.qty_input_type) {
        config.qty_input_type = this.qtyInputType;
      }

      if (this.step.stepTexts.topContent) {
        config.stepTexts.topContent = this.tinyTopSection;
      }
      // Save the visibility setup to the DB
      if (this.isDigitalUpsellStep && this.isLandingPageVisibilityAvailable) {
        config.isLandingPageVisible = this.isLandingPageVisible;
      }

      if (this.isDigitalUpsellStep && this.isPlansSetupAvailable && this.isPlansCountStaticSelect) {
        config.plans = {
          isGoodPlanPriceVisible: this.isGoodPlanPriceVisible,
          isUltimatePlanPriceVisible: this.isUltimatePlanPriceVisible,
          isPremiumPlanPriceVisible: this.isPremiumPlanPriceVisible,
          isGoodPriceStatic: this.isGoodPlanPriceStatic,
          isUltimatePriceStatic: this.isUltimatePlanPriceStatic,
          isPremiumPriceStatic: this.isPremiumPlanPriceStatic,
          goodPrice: this.isGoodPlanPriceStatic ? parseFloat(this.goodPlanStaticPrice) : 0,
          premiumPrice: this.isPremiumPlanPriceStatic ? parseFloat(this.premiumPlanStaticPrice) : 0,
          ultimatePrice: this.isUltimatePlanPriceStatic ? parseFloat(this.ultimatePlanStaticPrice) : 0,
          isGoodQtyStatic: this.isGoodPlanCountStatic,
          isPremiumQtyStatic: this.isPremiumPlanCountStatic,
          isUltimateQtyStatic: this.isUltimatePlanCountStatic,
          goodQty: this.isGoodPlanCountStatic ? parseInt(this.goodPlanStaticCount) : 0,
          premiumQty: this.isPremiumPlanCountStatic ? parseInt(this.premiumPlanStaticCount) : 0,
          ultimateQty: this.isUltimatePlanCountStatic ? parseInt(this.ultimatePlanStaticCount) : 0
        };
      }

      if (this.isListProcessingStep) {
        for (let item in this.listProcessingServices) {
          this.listProcessingServices[item] = Number.parseFloat(this.listProcessingServices[item]);
        }
        config.listProcessingServices = this.listProcessingServices;
      }

      if (this.isShippingOptionsStep) {
        config.third_party_shipping_method_enabled = this.isThirdPartyShippingEnabled;
        config.local_pickup_shipping_method_enabled = this.isLocalPickupShippingEnabled;
        config.courier_shipping_method_enabled = this.isCourierShippingEnabled;
        config.warehouse_shipping_method_enabled = this.isWarehouseShippingEnabled;
        config.free_on_board_shipping_method_enabled = this.isFreeOnBoardAvailable;
        config.local_delivery_shipping_method_enabled = this.isLocalDelivery;
        config.third_party_shipping_price = parseFloat(this.thirdPartyShippingPrice);
        config.local_pickup_shipping_price = parseFloat(this.localPickupShippingPrice);
        config.courier_shipping_price = parseFloat(this.courierShippingPrice);
        config.warehouse_shipping_price = parseFloat(this.warehouseShippingPrice);
        config.free_on_board_shipping_price = parseFloat(this.freeOnBoardShippingPrice);
        config.local_delivery_shipping_price = parseFloat(this.localDeliveryPrice);
      }

      if (this.isSubscriptionSelectorStep && this.availableSubscriptions.length) {
        config.subscription_options = Object.assign(
          {},
          ...this.availableSubscriptions.map(option => ({
            [option.id]: {
              is_enabled: this.selectedSubscriptions.includes(option.id),
              name: option.name,
              is_test: option.is_test,
              discount: parseInt(option.discount) > 0 && parseInt(option.discount) < 100 ? option.discount : 0,
              id: option.id,
              month_number: option.month_number,
              type: 'recurring'
            }
          }))
        );
      }

      if (this.isArtworkStep && this.initialcountTrueValues === 0) {
        this.scrollToOptions();
      } else {
        this.$store
          .dispatch('updateStep', config)
          .then(() => {
            this.showAlert('success', 'Saving of the step data has succeeded!');
          })
          .catch(() => {
            this.showAlert('error', 'Saving of the step data has failed! Please try again.');
          });
      }
    },
    checkMapAndMailListSwitches() {
      let counter = 0;
      const list = this.isMapListStep ? this.mapListOptions : this.isMailingListStep ? this.mailingListOptions : null;

      if (list) {
        Object.values(list).forEach(option => {
          if (option) {
            counter++;
          }
        });
        const isCounterZero = counter === 0;
        console.log('Returning value : ', !isCounterZero);
        return !isCounterZero;
      }
    },
    isSimpleText(item) {
      return typeof item === 'string';
    },
    onResetStep() {
      this.isResetStepDialog = true;
    },
    onResetStepConfirmed() {
      console.log('onResetStepConfirmed');
      this.$store.dispatch('resetStep', { selectedStepId: this.step.stepId, selectedOrder: this.order }).then(() => {
        this.isResetStepDialog = false;
      });
    },
    onResetStepRejected() {
      console.log('onResetStepRejected');
      this.isResetStepDialog = false;
    },
    formattedText(value) {
      return this.splitAndFormat(value, this);
    },
    hasAttributes(value) {
      return typeof value === 'object' || Array.isArray(value);
    },
    scrollToOptions() {
      var element = document.getElementById('step-top-section');
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    showAlert(type, message) {
      this.feedBack = { isVisible: true, type, message };
      this.$nextTick(() => {
        const element = document.querySelector('#step-feedback-alert');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
    },
    hideAlert() {
      this.feedBack.isVisible = false;
    },
    capitalize: function (text) {
      if (!text) return '';
      text = text.toString();
      const splittedText = text.split('-');

      if (splittedText.length === 1) {
        text = splittedText[0];
        return text.charAt(0).toUpperCase() + text.slice(1);
      }

      return `${splittedText[0].charAt(0).toUpperCase()}${splittedText[0].slice(1)} ${splittedText[1].charAt(0).toUpperCase()}${splittedText[1].slice(1)}`;
    },
    capitalizeSimpleText(text, context) {
      if (!text) return '';
      return isNaN(text) ? this.capitalize(text.toString()) : text.toString();
    },
    splitAndFormat(value, context) {
      if (!isNaN(value)) {
        return value;
      }

      const string = value.replace(/([a-zA-Z])(?=[A-Z])/g, '$1_');
      const splittedName = string.split('_');
      let formattedName = '';

      if (splittedName.length > 1) {
        for (let j in splittedName) {
          formattedName = `${formattedName} ${this.capitalizeSimpleText(splittedName[j], context)}`;
        }
      } else {
        formattedName = this.capitalizeSimpleText(splittedName[0], context);
      }
      return formattedName;
    },
    serviceRules(isActive) {
      return isActive
        ? [value => !!value || 'This field is required.', value => /^-?\d+$/.test(value) || 'Please enter a number', value => value >= 0 || 'Minimum value is O']
        : [];
    },
    changeInitialCountTrueValues(isIncrease) {
      isIncrease ? this.initialcountTrueValues++ : this.initialcountTrueValues--;
      this.isAtLeastOneSelected = this.initialcountTrueValues === 0;
    },
    getPriceField(item) {
      return item?.price ? 'price' : 'saturation';
    }
  }
};
</script>

<style scoped>
.step-layout {
  display: inline-block;
  width: 100%;
}

.step-layout h2 {
  margin-bottom: 1em;
}

.step-id {
  font-weight: inherit;
}

h3 {
  font-weight: bold;
}
.custom-text-filed {
  display: inline-block;
  margin-top: -6px;
  width: 25%;
}
</style>
